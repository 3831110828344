import Reacti, { useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Button, TextField, Typography } from "@mui/material";
import { welcome_msg } from "../content/content";
import { PageState } from "../App";

interface IWelcomePageProps {
  setPageState: (pageState: PageState) => void;
  setPassword: (password: string) => void;
  setAccessCode: (accesscode: string) => void;
}

export const WelcomePage = ({
  setPageState,
  setPassword,
  setAccessCode,
}: IWelcomePageProps) => {

  const [buttondisabled, setButtondisabled] = useState(true);

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <Typography>{welcome_msg}</Typography>
        </Grid2>
        <Grid2 xs={12} alignItems="center">
          <TextField
            id="password"
            label="Password"
            variant="outlined"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
              setButtondisabled(false);
            }}
          />
        </Grid2>
        <Grid2 xs={12} alignItems="center">
          <Button disabled={buttondisabled}  onClick={() => {setPageState(PageState.DOWNLOADING_DATA);}}>
            Log in to see My Museum’s data
          </Button>
        </Grid2>
        <Grid2 xs={12} alignItems="center">
        </Grid2>
        <Grid2 xs={12} alignItems="center">
        </Grid2>
        <Grid2 xs={12}>
          <Typography>You can also view the overall data by clicking the button below.</Typography>
        </Grid2>
        <Grid2 xs={12} alignItems="center">
          <Button onClick={() => {setPassword(""); setPageState(PageState.DOWNLOADING_DATA);}}>
            View Overall Data
          </Button>
        </Grid2>
        <Grid2 xs={12} alignItems="center">
        </Grid2>
        <Grid2 xs={12} alignItems="center">
        </Grid2>
        <Grid2 xs={12} alignItems="center">
        </Grid2>
        <Grid2 xs={12} alignItems="center">
        </Grid2>
        <Grid2 xs={12} alignItems="center">
        </Grid2>
        <Grid2 xs={12} alignItems="center">
        </Grid2>
        <Grid2 xs={12} alignItems="center">
          <Typography>If you received a special access code, please enter it in the box below and press the "View Overall Data" button above.</Typography>
          <TextField
            id="accesscode"
            label="Special Access Code"
            variant="outlined"
            type="password"
            onChange={(e) => {
              setAccessCode(e.target.value);
            }}
          />
        </Grid2>
      </Grid2>
    </>
  );
};
