export const staff_data = [
    {
      section_title: "PART 1: Staff demographics & work history ",
      charts: [
        {
            "id": 0,
            "labels" : ["the art museum field. Mean (in years).", "at your current museum. Mean (in years)"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [10.56, 7.02]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [11.86, 8.43]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [10.62, 7.25]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [9.75 , 6.74]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [9.86 , 6.61]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [9.39 , 5.54]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [10.05, 6.56]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [12.88, 8.66]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [10.58, 7.38]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [9.79 , 6.79]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [9.74 , 6.96]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [8.66 , 4.53]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [7.97 , 5.33]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [8.73 , 5.24]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [9.92 , 6.35]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [8.79 , 5.61]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [7.82 , 5.06]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [8.12 , 5.37]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [9.60 , 6.67]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [10.75, 6.30]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [11.95, 8.38]
              },
            ],
            "questionName": "Approximately how long have you been working in...",
            "graphType": "column",
            "questionType": "radiogroup"
        },
        {
            "id": 1,
            "labels" : ["<1", "1-3", "4-6", "7-10", "10-20", "20+"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.11,  0.13,  0.17,  0.16,  0.26,  0.17]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.05,  0.07,  0.10,  0.08,  0.58,  0.12]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.11,  0.13,  0.17,  0.16,  0.26,  0.18]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.12,  0.13,  0.20,  0.16,  0.24,  0.16]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.11,  0.12,  0.18,  0.18,  0.26,  0.15]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.11,  0.14,  0.19,  0.17,  0.27,  0.13]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.15,  0.15,  0.16,  0.14,  0.24,  0.16]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.07,  0.08,  0.15,  0.17,  0.30,  0.24]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.09,  0.15,  0.18,  0.18,  0.26,  0.15]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.15,  0.13,  0.19,  0.13,  0.24,  0.16]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.09,  0.18,  0.19,  0.13,  0.27,  0.15]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.11,  0.14,  0.23,  0.18,  0.23,  0.12]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.16,  0.17,  0.19,  0.18,  0.18,  0.12]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.14,  0.16,  0.20,  0.15,  0.23,  0.13]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.17,  0.21,  0.04,  0.17,  0.21,  0.21]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.11,  0.14,  0.23,  0.17,  0.23,  0.12]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.19,  0.15,  0.21,  0.21,  0.17,  0.08]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.18,  0.21,  0.16,  0.13,  0.16,  0.15]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.12,  0.15,  0.16,  0.17,  0.24,  0.15]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.10,  0.12,  0.19,  0.13,  0.25,  0.19]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.09,  0.11,  0.16,  0.16,  0.29,  0.20]
              },
            ],
            "questionName": "Approximately how long have you been working in the art museum field (in years)?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 2,
            "labels" : ["<1", "1-3", "4-6", "7-10", "10-20", "20+"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                 "data" : [0.20 , 0.20,  0.21,  0.16,  0.15 , 0.09]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.16,  0.17,  0.21,  0.16,  0.19 , 0.12]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.19,  0.19,  0.20,  0.16,  0.16 , 0.09]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.18,  0.17,  0.26,  0.16,  0.16 , 0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.22,  0.21,  0.19,  0.17,  0.13 , 0.08]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.20,  0.20,  0.25,  0.19,  0.11 , 0.05]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.23,  0.18,  0.19,  0.16,  0.15 , 0.08]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.15,  0.17,  0.18,  0.18,  0.20 , 0.12]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.15,  0.22,  0.22,  0.17,  0.14 , 0.09]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.28,  0.17,  0.20,  0.11,  0.16 , 0.09]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.14,  0.27,  0.20,  0.17,  0.14 , 0.08]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.26,  0.27,  0.24,  0.12,  0.07 , 0.04]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.28,  0.18,  0.20,  0.17,  0.11 , 0.06]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.22,  0.23,  0.25,  0.14,  0.11 , 0.05]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.25,  0.25,  0.04,  0.21,  0.17 , 0.08]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.22,  0.21,  0.26,  0.14,  0.10 , 0.06]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.31,  0.23,  0.25,  0.10,  0.04 , 0.06]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.25,  0.26,  0.15,  0.18,  0.09 , 0.07]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.21,  0.19,  0.20,  0.18,  0.15 , 0.07]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.19,  0.23,  0.21,  0.16,  0.14 , 0.07]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.16,  0.17,  0.20,  0.16,  0.19 , 0.12]
              },
            ],
            "questionName": "And how long have you been working at your current museum (in years)?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 3,
            "labels" : ["Entry-level", "Associate/Experienced", "Manager", "Director", "Executive/Museum Leadership"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.12,  0.44,  0.26,  0.08 , 0.06]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.12,  0.49,  0.27,  0.05 , 0.04]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.13,  0.46,  0.25,  0.07 , 0.05]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.13,  0.43,  0.24,  0.08 , 0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.10,  0.45,  0.25,  0.10 , 0.06]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.10,  0.46,  0.26,  0.12 , 0.04]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.17,  0.42,  0.26,  0.05 , 0.04]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.09,  0.46,  0.26,  0.10 , 0.05]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.11,  0.48,  0.25,  0.04 , 0.08]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.16,  0.38,  0.29,  0.06 , 0.07]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.19,  0.41,  0.23,  0.09 , 0.05]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.10,  0.33,  0.30,  0.14 , 0.09]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.15,  0.42,  0.24,  0.09 , 0.06]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.13,  0.45,  0.26,  0.08 , 0.05]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.13,  0.33,  0.33,  0.08 , 0.08]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.12,  0.35,  0.28,  0.12 , 0.09]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.17,  0.35,  0.38,  0.06 , 0.04]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.18,  0.39,  0.24,  0.08 , 0.09]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.11,  0.46,  0.22,  0.07 , 0.07]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.12,  0.44,  0.25,  0.10 , 0.06]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.12,  0.49,  0.26,  0.06 , 0.03]
              },
            ],
            "questionName": "Which of the following best describes your current position level in the museum?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 4,
            "labels" : ["Full-time/ Permanent employee", "Part-time employee", "Temporary employee", "Seasonal employee", "Paid intern", "Apprentice / Fellow"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.86,  0.10,  0.02,  0.00 , 0.01 , 0.00 , 0.01]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.89,  0.06,  0.03,  0.00 , 0.01 , 0.00 , 0.01]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.86,  0.10,  0.02,  0.00 , 0.01 , 0.00 , 0.01]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.77,  0.15,  0.04,  0.00 , 0.00 , 0.00 , 0.02]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.84,  0.13,  0.02,  0.01 , 0.00 , 0.00 , 0.01]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.90,  0.07,  0.02,  0.00 , 0.00 , 0.00 , 0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.85,  0.09,  0.02,  0.00 , 0.01 , 0.00 , 0.01]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.87,  0.09,  0.02,  0.01 , 0.00 , 0.00 , 0.01]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.83,  0.11,  0.03,  0.00 , 0.00 , 0.00 , 0.02]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.87,  0.08,  0.02,  0.00 , 0.02 , 0.00 , 0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.78,  0.17,  0.03,  0.00 , 0.00 , 0.00 , 0.03]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.83,  0.13,  0.01,  0.01 , 0.00 , 0.00 , 0.02]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.77,  0.19,  0.01,  0.00 , 0.01 , 0.00 , 0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.93,  0.04,  0.02,  0.00 , 0.00 , 0.00 , 0.01]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.79,  0.08,  0.08,  0.00 , 0.04 , 0.00 , 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.78,  0.16,  0.03,  0.00 , 0.00 , 0.00 , 0.02]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.83,  0.17,  0.00,  0.00 , 0.00 , 0.00 , 0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.87,  0.10,  0.00,  0.00 , 0.02 , 0.00 , 0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.85,  0.14,  0.00,  0.00 , 0.00 , 0.00 , 0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.86,  0.10,  0.02,  0.00 , 0.00 , 0.00 , 0.01]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.89,  0.06,  0.03,  0.01 , 0.01 , 0.00 , 0.01]
              },
            ],
            "questionName": "What type of position do you have at the museum?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 5,
            "labels" : ["Administration", "Building Operations", "Collections & Exhibitions", "Communications", "Public Engagement"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.30,  0.17,  0.24,  0.11,  0.19]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.29,  0.18,  0.27,  0.10,  0.16]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.30,  0.17,  0.24,  0.10,  0.19]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.29,  0.19,  0.20,  0.11,  0.21]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.33,  0.16,  0.21,  0.12,  0.19]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.31,  0.14,  0.26,  0.11,  0.18]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.27,  0.19,  0.24,  0.09,  0.21]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.29,  0.14,  0.29,  0.11,  0.18]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.28,  0.19,  0.22,  0.10,  0.21]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.33,  0.17,  0.24,  0.09,  0.16]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.33,  0.17,  0.16,  0.12,  0.22]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.31,  0.19,  0.17,  0.11,  0.22]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.27,  0.24,  0.15,  0.10,  0.23]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.32,  0.16,  0.24,  0.12,  0.17]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.28,  0.19,  0.16,  0.16,  0.22]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.29,  0.20,  0.18,  0.11,  0.22]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.33,  0.20,  0.15,  0.09,  0.24]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.33,  0.23,  0.13,  0.10,  0.20]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.29,  0.17,  0.19,  0.11,  0.24]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.29,  0.16,  0.26,  0.09,  0.20]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.30,  0.15,  0.31,  0.11,  0.14]
              },
            ],
            "questionName": "Which of the following categories does your current museum position fall into? Please select ALL that apply.",
            "graphType": "column",
            "questionType": "checkbox"
          },
          {
            "id": 6,
            "labels" : ["The Silent Generation (1928-1945)", "Baby Boomers (1946-1964)", "Generation X (1965-1980)", "Millennial (1981-1996)", "Generation Z (1997-2012)"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.00,  0.14,  0.32,  0.47,  0.07],
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.00,  0.19,  0.34,  0.43,  0.05],
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.00,  0.15,  0.32,  0.46,  0.07],
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.00,  0.17,  0.36,  0.39,  0.08],
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.00,  0.13,  0.28,  0.51,  0.08],
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.00,  0.10,  0.30,  0.53,  0.07],
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.00,  0.14,  0.31,  0.46,  0.09],
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.00,  0.15,  0.37,  0.43,  0.05],
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.00,  0.16,  0.28,  0.48,  0.08],
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.00,  0.12,  0.28,  0.52,  0.08],
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00,  0.19,  0.30,  0.43,  0.08],
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.00,  0.07,  0.28,  0.54,  0.11],
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.00,  0.16,  0.29,  0.42,  0.13],
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.00,  0.09,  0.32,  0.53,  0.05],
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  0.17,  0.38,  0.42,  0.04],
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.00,  0.14,  0.30,  0.47,  0.09],
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00,  0.23,  0.15,  0.52,  0.10],
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00,  0.10,  0.27,  0.47,  0.16],
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.00,  0.15,  0.31,  0.46,  0.08],
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.00,  0.11,  0.36,  0.47,  0.06],
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.00,  0.15,  0.33,  0.47,  0.05],
              },
            ],
            "questionName": "In what year were you born?",
            "graphType": "column",
            "questionType": "checkbox"
          },
          {
            "id": 106,
            "labels" : ["Man", "Women", "Non-binary and Another Gender"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.68,  0.24,  0.09]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.69,  0.24,  0.06]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.67,  0.24,  0.09]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.71,  0.23,  0.06]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.65,  0.26,  0.10]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.74,  0.18,  0.08]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.67,  0.23,  0.11]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.64,  0.26,  0.09]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.67,  0.25,  0.08]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.70,  0.23,  0.07]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.69,  0.21,  0.10]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.66,  0.24,  0.10]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.68,  0.20,  0.12]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.66,  0.25,  0.09]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.75,  0.17,  0.08]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.71,  0.22,  0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.71,  0.17,  0.13]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.63,  0.20,  0.17]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.66,  0.23,  0.11]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.66,  0.25,  0.09]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.66,  0.26,  0.08]
              },
            ],
            "questionName": "What is your gender?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 7,
            "labels" : ["Heterosexual", "LGBTQ", "Prefer Not To Answer"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.66,  0.28,  0.06]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.72,  0.22,  0.07]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.66,  0.27,  0.06]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.72,  0.23,  0.05]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.65,  0.29,  0.06]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.59,  0.35,  0.06]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.64,  0.28,  0.08]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.67,  0.26,  0.06]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.67,  0.28,  0.05]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.71,  0.25,  0.04]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.73,  0.21,  0.06]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.66,  0.31,  0.04]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.63,  0.28,  0.09]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.61,  0.33,  0.06]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.79,  0.13,  0.08]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.72,  0.24,  0.04]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.52,  0.42,  0.06]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.55,  0.37,  0.08]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.65,  0.28,  0.07]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.62,  0.33,  0.05]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.69,  0.25,  0.07]
              },
            ],
            "questionName": "How would you define your sexual orientation?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 8,
            "labels" : ["Black or African American", "East Asian", "Hispanic, Latina, Latino, or Latinx", "Middle Eastern or North African", "Native American/Alaska Native/First Nations", "Native Hawaiian or other Pacific Islander", "South Asian", "Southeast Asian", "White", "Another race of ethnicity"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.08,  0.05,  0.11 , 0.01,  0.02,  0.01,  0.01,  0.02,  0.73,  0.02]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.09,  0.04,  0.11 , 0.01,  0.02,  0.00,  0.01,  0.02,  0.72,  0.02]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.08,  0.05,  0.10 , 0.01,  0.02,  0.01,  0.01,  0.02,  0.75,  0.02]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.08,  0.07,  0.07 , 0.00,  0.02,  0.00,  0.00,  0.01,  0.77,  0.01]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.08,  0.04,  0.13 , 0.02,  0.02,  0.00,  0.01,  0.02,  0.73,  0.03]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.13,  0.04,  0.25 , 0.01,  0.02,  0.00,  0.02,  0.02,  0.61,  0.04]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.06,  0.07,  0.08 , 0.01,  0.02,  0.01,  0.01,  0.01,  0.75,  0.02]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.08,  0.05,  0.10 , 0.02,  0.02,  0.00,  0.01,  0.02,  0.71,  0.03]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.08,  0.02,  0.04 , 0.01,  0.02,  0.00,  0.01,  0.01,  0.83,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.09,  0.03,  0.14 , 0.01,  0.03,  0.00,  0.01,  0.03,  0.73,  0.04]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.03,  0.03,  0.05 , 0.03,  0.00,  0.00,  0.01,  0.03,  0.88,  0.03]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.11,  0.07,  0.21 , 0.03,  0.03,  0.01,  0.01,  0.02,  0.61,  0.02]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.15,  0.03,  0.15 , 0.00,  0.00,  0.00,  0.00,  0.01,  0.67,  0.02]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.04,  0.10,  0.16 , 0.01,  0.04,  0.02,  0.02,  0.03,  0.67,  0.03]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.17,  0.08,  0.04 , 0.00,  0.00,  0.00,  0.00,  0.00,  0.79,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.10,  0.05,  0.16 , 0.02,  0.02,  0.00,  0.01,  0.01,  0.70,  0.02]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.06,  0.02,  0.15 , 0.00,  0.04,  0.00,  0.00,  0.02,  0.83,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.10,  0.04,  0.08 , 0.01,  0.00,  0.00,  0.01,  0.03,  0.75,  0.01]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.06,  0.08,  0.11 , 0.01,  0.02,  0.02,  0.00,  0.02,  0.71,  0.03]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.09,  0.06,  0.09 , 0.01,  0.04,  0.00,  0.03,  0.01,  0.71,  0.03]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.07,  0.04,  0.10 , 0.01,  0.01,  0.00,  0.01,  0.02,  0.76,  0.02]
              },
            ],
            "questionName": "With which of the following racial and ethnic groups do you identify as? Please select all that apply.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 9,
            "labels" : ["Asian or Asian American", "Middle Eastern or North African", "Black or African American", "Hispanic or Latina/o/x", "Native American or Alaska Native", "Native Hawaiian or other Pacific Islander", "White or European American", "Multiracial", "Other Race"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.08,  0.01,  0.08,  0.11,  0.02,  0.01,  0.73,  0.05 , 0.02]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.06,  0.01,  0.09,  0.11,  0.02,  0.00,  0.72,  0.04 , 0.02]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.08,  0.01,  0.08,  0.10,  0.02,  0.01,  0.75,  0.05 , 0.02]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.08,  0.00,  0.08,  0.07,  0.02,  0.00,  0.77,  0.04 , 0.01]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.07,  0.02,  0.08,  0.13,  0.02,  0.00,  0.73,  0.06 , 0.03]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.08,  0.01,  0.13,  0.25,  0.02,  0.00,  0.61,  0.06 , 0.04]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.09,  0.01,  0.06,  0.08,  0.02,  0.01,  0.75,  0.06 , 0.02]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.09,  0.02,  0.08,  0.10,  0.02,  0.00,  0.71,  0.05 , 0.03]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.04,  0.01,  0.08,  0.04,  0.02,  0.00,  0.83,  0.06 , 0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.06,  0.01,  0.09,  0.14,  0.03,  0.00,  0.73,  0.06 , 0.04]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.07,  0.03,  0.03,  0.05,  0.00,  0.00,  0.88,  0.04 , 0.03]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.09,  0.03,  0.11,  0.21,  0.03,  0.01,  0.61,  0.07 , 0.02]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.04,  0.00,  0.15,  0.15,  0.00,  0.00,  0.67,  0.03 , 0.02]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.15,  0.01,  0.04,  0.16,  0.04,  0.02,  0.67,  0.08 , 0.03]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.08,  0.00,  0.17,  0.04,  0.00,  0.00,  0.79,  0.08 , 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.06,  0.02,  0.10,  0.16,  0.02,  0.00,  0.70,  0.05 , 0.02]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.04,  0.00,  0.06,  0.15,  0.04,  0.00,  0.83,  0.06 , 0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.08,  0.01,  0.10,  0.08,  0.00,  0.00,  0.75,  0.03 , 0.01]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.10,  0.01,  0.06,  0.11,  0.02,  0.02,  0.71,  0.07 , 0.03]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.10,  0.01,  0.09,  0.09,  0.04,  0.00,  0.71,  0.06 , 0.03]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.07,  0.01,  0.07,  0.10,  0.01,  0.00,  0.76,  0.05 , 0.02]
              },
            ],
            "questionName": "With which of the following racial and ethnic groups do you identify as? Please select all that apply. (Recorded to match Census categories)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 10,
            "labels" : ["Some high school", "High school graduate", "Some college/Associates degree", "Bachelor’s degree", "Master’s degree", "Professional or doctorate degree"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.00,  0.02,  0.09,  0.39,  0.41,  0.08]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.00,  0.01,  0.09,  0.35,  0.44,  0.10]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.00,  0.02,  0.09,  0.39,  0.40,  0.08]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.00,  0.03,  0.09,  0.39,  0.36,  0.10]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.00,  0.01,  0.09,  0.43,  0.41,  0.05]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.01,  0.01,  0.07,  0.45,  0.37,  0.06]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.00,  0.02,  0.10,  0.39,  0.38,  0.08]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.00,  0.01,  0.06,  0.33,  0.46,  0.12]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.00,  0.01,  0.09,  0.40,  0.41,  0.09]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.00,  0.03,  0.09,  0.39,  0.42,  0.05]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00,  0.03,  0.13,  0.43,  0.36,  0.06]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.00,  0.01,  0.08,  0.42,  0.44,  0.04]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.00,  0.03,  0.12,  0.44,  0.31,  0.04]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.00,  0.01,  0.09,  0.45,  0.38,  0.04]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  0.00,  0.08,  0.33,  0.46,  0.04]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.00,  0.03,  0.10,  0.40,  0.40,  0.05]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00,  0.02,  0.10,  0.52,  0.29,  0.04]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00,  0.01,  0.13,  0.49,  0.24,  0.08]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.00,  0.03,  0.11,  0.45,  0.33,  0.05]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.00,  0.01,  0.06,  0.39,  0.44,  0.09]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.00,  0.01,  0.08,  0.35,  0.46,  0.09]
              },
            ],
            "questionName": "What is the highest level of education that you’ve completed?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 11,
            "labels" : ["Yes","No"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.16,  0.78]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.13,  0.80]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.16,  0.77]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.18,  0.79]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.14,  0.80]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.19,  0.74]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.19,  0.74]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.14,  0.79]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.17,  0.77]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.16,  0.78]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.17,  0.78]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.17,  0.78]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.20,  0.73]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.17,  0.77]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.25,  0.67]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.16,  0.80]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.19,  0.75]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.28,  0.65]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.17,  0.77]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.21,  0.74]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.12,  0.80]
              },
            ],
            "questionName": "Do you identify as a person with a disability and/or as neuroatypical/neurodivergent?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
        ]
      },
      {
        section_title: "PART 2: Compensation details & promotion rates",
        charts: [
          {
            "id": 12,
            "labels" : ["Annual Salary","Hourly Wage"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.65,  0.35]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.67,  0.33]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.63,  0.37]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.64,  0.35]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.65,  0.35]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.60,  0.40]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.55,  0.45]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.74,  0.26]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.59,  0.40]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.70,  0.29]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.58,  0.43]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.72,  0.27]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.62,  0.38]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.53,  0.47]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.75,  0.25]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.68,  0.31]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.60,  0.40]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.56,  0.44]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.60,  0.40]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.59,  0.41]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.67,  0.33]
              },
            ],
            "questionName": "How are you compensated in this position?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 13,
            "labels" : ["Above others","About the same as others","Below others","N/A, there aren’t others in my institution with similar position level"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.08,  0.50,  0.36,  0.05]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.11,  0.50,  0.36,  0.04]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.08,  0.50,  0.36,  0.05]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.07,  0.46,  0.40,  0.07]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.08,  0.51,  0.35,  0.06]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.07,  0.56,  0.30,  0.06]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.07,  0.49,  0.39,  0.04]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.09,  0.51,  0.34,  0.05]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.11,  0.44,  0.41,  0.04]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.07,  0.52,  0.35,  0.05]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.03,  0.56,  0.35,  0.06]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.07,  0.52,  0.36,  0.06]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.05,  0.47,  0.42,  0.06]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.07,  0.54,  0.32,  0.06]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.13,  0.42,  0.42,  0.04]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.07,  0.48,  0.38,  0.08]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.10,  0.44,  0.46,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.06,  0.44,  0.45,  0.05]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.05,  0.52,  0.35,  0.08]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.08,  0.57,  0.31,  0.05]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.10,  0.51,  0.36,  0.04]
              },
            ],
            "questionName": "Compared to people at similar position levels (e.g., entry level, associate, manager, executive) in my institution, I think my salary is:",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 14,
            "labels" : ["Above others","About the same as others","Below others"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.13,  0.39,  0.48]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.26,  0.41,  0.34]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.13,  0.40,  0.47]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.12,  0.38,  0.51]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.08,  0.43,  0.49]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.13,  0.39,  0.48]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.09,  0.38,  0.53]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.19,  0.34,  0.47]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.07,  0.41,  0.52]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.10,  0.47,  0.43]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.03,  0.49,  0.48]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.05,  0.36,  0.59]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.06,  0.35,  0.59]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.16,  0.43,  0.40]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.04,  0.29,  0.67]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.07,  0.35,  0.58]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.10,  0.42,  0.48]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.02,  0.42,  0.56]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.08,  0.42,  0.50]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.08,  0.41,  0.51]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.21,  0.40,  0.39]
              },
            ],
            "questionName": "Compared to people at other art museums with comparable position levels (e.g., entry level, associate, manager, director, executive), I think my salary is:",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 15,
            "labels" : ["A simultaneous promotion with title change and a pay increase beyond cost of living","A promotion with title change but no accompanying pay increase beyond cost of living", "A pay increase beyond cost of living without a change in title","None of the above"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.31,  0.12,  0.31,  0.44]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.35,  0.09,  0.33,  0.41]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.31,  0.12,  0.32,  0.44]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.25,  0.08,  0.32,  0.45]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.31,  0.14,  0.31,  0.45]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.32,  0.12,  0.34,  0.40]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.29,  0.11,  0.27,  0.50]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.36,  0.13,  0.33,  0.42]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.28,  0.12,  0.34,  0.42]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.35,  0.13,  0.31,  0.45]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.29,  0.13,  0.33,  0.47]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.27,  0.09,  0.22,  0.54]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.23,  0.08,  0.27,  0.52]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.30,  0.10,  0.29,  0.47]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.21,  0.08,  0.29,  0.46]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.26,  0.07,  0.27,  0.51]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.42,  0.13,  0.23,  0.52]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.26,  0.12,  0.30,  0.50]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.26,  0.15,  0.32,  0.48]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.29,  0.07,  0.38,  0.42]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.37,  0.13,  0.31,  0.41]
              },
            ],
            "questionName": "Have you ever received any of the following combinations of promotions and pay increases while at your current museum? Select all that apply. (Please consider ONLY pay increases beyond cost of living or inflation adjustment - typically 2-3% per year).",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 16,
            "labels" : ["All", "Men", "Not Men","White","Not White"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.05, 1.00, 1.04, 1.00, 0.91]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.06, 1.00, 0.82, 1.00, 1.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.05, 1.00, 0.95, 1.00, 0.87]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.04, 1.00, 1.07, 1.00, 0.59]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.06, 1.00, 1.33, 1.00, 0.90]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.07, 1.00, 1.24, 1.00, 0.78]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.05, 1.00, 0.79, 1.00, 1.03]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.06, 1.00, 0.96, 1.00, 0.75]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.05, 1.00, 0.84, 1.00, 1.46]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.06, 1.00, 1.48, 1.00, 0.94]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.06, 1.00, 1.38, 1.00, 1.78]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.06, 1.00, 2.74, 1.00, 1.13]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.05, 1.00, 1.23, 1.00, 0.79]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.05, 1.00, 1.04, 1.00, 0.79]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.03, 1.00, 1.00, 1.00, 1.66]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.05, 1.00, 1.73, 1.00, 1.25]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.08, 1.00, 0.37, 1.00, 0.93]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.07, 1.00, 1.21, 1.00, 0.97]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.05, 1.00, 0.89, 1.00, 0.84]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.04, 1.00, 1.19, 1.00, 0.82]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.06, 1.00, 0.94, 1.00, 0.88]
              },
            ],
            "questionName": "Parity score showing the proportional likelihood of having received a promotion with title change and pay increase beyond cost of living by gender, race & ethnicity",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 17,
            "labels" : ["All", "Men", "Not Men","White","Not White"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.02, 1.00, 1.85, 1.00, 1.28]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.01, 1.00, 2.08, 1.00, 0.86]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.01, 1.00, 1.73, 1.00, 1.24]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.01, 1.00, 1.12, 1.00, 1.37]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.02, 1.00, 1.71, 1.00, 1.51]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.02, 1.00, 1.62, 1.00, 1.94]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.01, 1.00, 2.25, 1.00, 0.72]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.01, 1.00, 3.37, 1.00, 1.46]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.02, 1.00, 2.17, 1.00, 1.11]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.02, 1.00, 0.91, 1.00, 1.53]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.02, 1.00, 0.78, 1.00, 2.08]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.02, 1.00, 3.68, 1.00, 2.04]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.01, 1.00, 4.16, 1.00, 3.52]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.02, 1.00, 1.22, 1.00, 0.45]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00, 1.00, 0.00, 1.00, 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.01, 1.00, 2.56, 1.00, 2.03]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.03, 1.00, 1.00, 1.00, 0.78]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.02, 1.00, 1.89, 1.00, 1.82]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.02, 1.00, 2.55, 1.00, 1.48]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.01, 1.00, 1.21, 1.00, 0.66]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.01, 1.00, 1.51, 1.00, 1.11]
              },
            ],
            "questionName": "Parity score showing the proportional likelihood of having received a promotion with title change but no pay increase beyond cost of living by gender, race & ethnicity",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 18,
            "labels" : ["All", "Men", "Not Men","White","Not White"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.07,1.00,1.03,1.00,0.82]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.09,1.00,1.13,1.00,0.71]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.07,1.00,1.19,1.00,0.83]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.07,1.00,0.78,1.00,1.40]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.08,1.00,1.00,1.00,0.81]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.07,1.00,0.89,1.00,0.90]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.07,1.00,1.15,1.00,0.69]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.06,1.00,1.15,1.00,0.80]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.09,1.00,0.99,1.00,1.41]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.09,1.00,0.85,1.00,0.28]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.07,1.00,1.91,1.00,2.01]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.08,1.00,0.36,1.00,0.86]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.08,1.00,1.38,1.00,0.80]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.07,1.00,0.78,1.00,0.88]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.03,1.00,0.36,1.00,0.99]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.08,1.00,0.55,1.00,1.24]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.11,1.00,2.23,1.00,0.41]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.11,1.00,1.24,1.00,1.60]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.09,1.00,1.52,1.00,0.50]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.07,1.00,0.90,1.00,0.99]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.07,1.00,1.13,1.00,0.69]
              },
            ],
            "questionName": "Parity score showing the proportional likelihood of having received a promotion with pay increase beyond cost of living but no title change by gender, race & ethnicity",
            "graphType": "column",
            "questionType": "radiogroup"
          },
        ]
      },
      {
        section_title: "PART 3: Staff experiences",
        charts: [
          {
            "id": 19,
            "labels" : ["1 = Not at all satisfied", "2", "3","4", "5 = Extremely Satisfied"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.18,  0.28 , 0.25,  0.23,  0.07]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.14,  0.25 , 0.26,  0.25,  0.11]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.18,  0.27 , 0.25,  0.23,  0.07]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.13,  0.28 , 0.26,  0.24,  0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.18,  0.31 , 0.23,  0.22,  0.06]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.19,  0.29 , 0.23,  0.22,  0.07]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.21,  0.28 , 0.28,  0.18,  0.05]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.20,  0.26 , 0.22,  0.25,  0.07]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.19,  0.28 , 0.23,  0.22,  0.07]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.14,  0.25 , 0.30,  0.23,  0.07]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.20,  0.32 , 0.25,  0.18,  0.06]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.17,  0.32 , 0.25,  0.19,  0.07]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.21,  0.27 , 0.27,  0.19,  0.06]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.14,  0.30 , 0.26,  0.23,  0.07]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.13,  0.33 , 0.29,  0.08,  0.17]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.18,  0.29 , 0.25,  0.22,  0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.08,  0.38 , 0.33,  0.19,  0.02]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.28,  0.22 , 0.22,  0.21,  0.06]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.16,  0.29 , 0.29,  0.20,  0.06]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.19,  0.27 , 0.26,  0.23,  0.05]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.18,  0.27 , 0.22,  0.25,  0.08]
              },
            ],
            "questionName": "In your current employment situation, how satisfied are you with the level of pay.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 20,
            "labels" : ["1 = Not at all satisfied", "2", "3","4", "5 = Extremely Satisfied"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.04,  0.08,  0.18,  0.37,  0.33]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.02,  0.07,  0.14,  0.38,  0.38]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.04,  0.08,  0.18,  0.37,  0.33]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.03,  0.06,  0.15,  0.40,  0.36]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.05,  0.09,  0.18,  0.34,  0.34]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.04,  0.08,  0.17,  0.40,  0.30]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.03,  0.11,  0.20,  0.37,  0.29]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.06,  0.08,  0.17,  0.36,  0.33]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.04,  0.09,  0.17,  0.36,  0.34]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.02,  0.06,  0.19,  0.35,  0.39]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.01,  0.13,  0.20,  0.34,  0.33]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.04,  0.11,  0.18,  0.30,  0.38]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.05,  0.09,  0.19,  0.36,  0.31]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.02,  0.08,  0.18,  0.41,  0.31]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  0.08,  0.29,  0.33,  0.29]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.04,  0.08,  0.17,  0.36,  0.35]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.04,  0.08,  0.15,  0.42,  0.31]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.05,  0.13,  0.19,  0.33,  0.29]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.03,  0.10,  0.21,  0.32,  0.34]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.04,  0.09,  0.17,  0.43,  0.26]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.04,  0.07,  0.17,  0.37,  0.35]
              },
            ],
            "questionName": "In your current employment situation, how satisfied are you with the stability and predictability of pay.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 21,
            "labels" : ["1 = Not at all satisfied", "2", "3","4", "5 = Extremely Satisfied"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.04,  0.10 , 0.19,  0.36,  0.32]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.03,  0.08 , 0.17,  0.37,  0.34]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.04,  0.09 , 0.18,  0.37,  0.32]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.03,  0.08 , 0.17,  0.33,  0.39]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.04,  0.10 , 0.20,  0.35,  0.31]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.04,  0.10 , 0.19,  0.36,  0.32]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.04,  0.10 , 0.20,  0.37,  0.29]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.05,  0.12 , 0.20,  0.36,  0.27]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.02,  0.08 , 0.17,  0.41,  0.32]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.03,  0.08 , 0.19,  0.31,  0.39]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.05,  0.09 , 0.13,  0.35,  0.38]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.04,  0.14 , 0.26,  0.29,  0.27]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.05,  0.10 , 0.22,  0.31,  0.33]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.03,  0.08 , 0.18,  0.39,  0.32]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  0.13 , 0.21,  0.46,  0.21]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.04,  0.09 , 0.21,  0.32,  0.34]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.08,  0.06 , 0.23,  0.42,  0.21]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.05,  0.13 , 0.17,  0.36,  0.29]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.02,  0.10 , 0.20,  0.31,  0.36]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.05,  0.08 , 0.18,  0.39,  0.30]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.04,  0.10 , 0.17,  0.38,  0.31]
              },
            ],
            "questionName": "In your current employment situation, how satisfied are you with the stability and predictability of hours.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 22,
            "labels" : ["1 = Not at all satisfied", "2", "3","4", "5 = Extremely Satisfied"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.06,  0.15,  0.21,  0.32,  0.26]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.07,  0.18,  0.20,  0.32,  0.23]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.06,  0.15,  0.21,  0.32,  0.26]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.04,  0.15,  0.22,  0.31,  0.28]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.04,  0.15,  0.20,  0.32,  0.29]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.05,  0.13,  0.22,  0.32,  0.29]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.06,  0.16,  0.24,  0.31,  0.24]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.08,  0.15,  0.24,  0.30,  0.23]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.04,  0.18,  0.18,  0.35,  0.25]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.04,  0.15,  0.19,  0.33,  0.30]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.07,  0.13,  0.20,  0.32,  0.29]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.02,  0.17,  0.20,  0.31,  0.30]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.04,  0.13,  0.23,  0.30,  0.31]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.06,  0.15,  0.20,  0.32,  0.27]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  0.21,  0.29,  0.33,  0.17]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.04,  0.15,  0.21,  0.29,  0.31]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.06,  0.08,  0.21,  0.42,  0.23]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.05,  0.11,  0.22,  0.42,  0.22]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.05,  0.15,  0.17,  0.29,  0.34]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.06,  0.13,  0.24,  0.30,  0.26]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.07,  0.17,  0.22,  0.32,  0.22]
              },
            ],
            "questionName": "In your current employment situation, how satisfied are you with your control over hours and/or location (e.g., ability to work flexible hours, work remotely).",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 23,
            "labels" : ["1 = Not at all satisfied", "2", "3","4", "5 = Extremely Satisfied"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.04,  0.07,  0.21 , 0.38,  0.30]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.04,  0.05,  0.16 , 0.38,  0.37]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.04,  0.08,  0.21 , 0.38,  0.30]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.05,  0.07,  0.22 , 0.34,  0.32]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.03,  0.07,  0.21 , 0.40,  0.29]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.05,  0.06,  0.27 , 0.31,  0.31]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.04,  0.10,  0.22 , 0.40,  0.25]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.04,  0.08,  0.22 , 0.33,  0.33]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.04,  0.08,  0.18 , 0.42,  0.28]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.02,  0.05,  0.17 , 0.43,  0.32]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.01,  0.06,  0.20 , 0.42,  0.32]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.03,  0.04,  0.24 , 0.38,  0.32]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.02,  0.08,  0.21 , 0.39,  0.30]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.04,  0.09,  0.26 , 0.36,  0.25]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  0.17,  0.17 , 0.42,  0.25]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.04,  0.06,  0.22 , 0.37,  0.31]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.02,  0.06,  0.08 , 0.56,  0.27]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.04,  0.09,  0.18 , 0.40,  0.28]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.03,  0.07,  0.24 , 0.37,  0.29]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.04,  0.09,  0.25 , 0.35,  0.27]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.03,  0.08,  0.20 , 0.38,  0.31]
              },
            ],
            "questionName": "In your current employment situation, how satisfied are you with the job security.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 24,
            "labels" : ["1 = Not at all satisfied", "2", "3","4", "5 = Extremely Satisfied"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.09,  0.12,  0.21,  0.35,  0.24]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.07,  0.08,  0.14,  0.38,  0.32]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.09,  0.11,  0.20,  0.35,  0.25]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.07,  0.04,  0.11,  0.36,  0.42]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.10,  0.15,  0.23,  0.31,  0.21]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.11,  0.14,  0.21,  0.35,  0.19]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.09,  0.13,  0.26,  0.36,  0.17]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.11,  0.14,  0.17,  0.35,  0.22]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.11,  0.16,  0.21,  0.30,  0.22]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.04,  0.08,  0.21,  0.30,  0.37]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.08,  0.08,  0.35,  0.33,  0.16]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.10,  0.17,  0.28,  0.28,  0.17]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.11,  0.11,  0.16,  0.36,  0.26]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.05,  0.09,  0.25,  0.39,  0.22]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.08,  0.00,  0.08,  0.54,  0.29]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.09,  0.09,  0.19,  0.32,  0.31]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.04,  0.10,  0.29,  0.33,  0.23]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.12,  0.11,  0.20,  0.39,  0.18]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.08,  0.15,  0.25,  0.31,  0.21]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.09,  0.12,  0.23,  0.38,  0.18]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.09,  0.12,  0.19,  0.34,  0.26]
              },
            ],
            "questionName": "In your current employment situation, how satisfied are you with the employee benefits (e.g., health care, retirement).",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 25,
            "labels" : ["1 = Not at all satisfied", "2", "3","4", "5 = Extremely Satisfied"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.19,  0.22,  0.31,  0.21,  0.07]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.20,  0.25,  0.27,  0.21,  0.07]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.20,  0.23,  0.30,  0.21,  0.08]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.13,  0.16,  0.37,  0.22,  0.12]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.16,  0.21,  0.34,  0.21,  0.08]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.23,  0.24,  0.27,  0.20,  0.06]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.22,  0.26,  0.26,  0.20,  0.06]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.22,  0.24,  0.30,  0.20,  0.05]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.20,  0.22,  0.31,  0.18,  0.08]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.13,  0.19,  0.29,  0.28,  0.11]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.10,  0.23,  0.32,  0.24,  0.11]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.10,  0.17,  0.41,  0.24,  0.08]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.20,  0.23,  0.31,  0.16,  0.10]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.18,  0.21,  0.32,  0.23,  0.06]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.04,  0.08,  0.54,  0.29,  0.04]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.12,  0.17,  0.38,  0.23,  0.10]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.06,  0.33,  0.31,  0.19,  0.10]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.25,  0.23,  0.22,  0.18,  0.13]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.13,  0.23,  0.32,  0.23,  0.08]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.23,  0.25,  0.24,  0.22,  0.07]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.22,  0.23,  0.30,  0.19,  0.05]
              },
            ],
            "questionName": "In your current employment situation, how satisfied are you with the career advancement opportunities (e.g., promotion path, learning new skills).",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 26,
            "labels" : ["1 = Not at all satisfied", "2", "3","4", "5 = Extremely Satisfied"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.05,  0.12,  0.26,  0.37,  0.20]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.06,  0.14,  0.25,  0.37,  0.18]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.05,  0.13,  0.25,  0.37,  0.20]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.03,  0.10,  0.26,  0.36,  0.25]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.04,  0.12,  0.25,  0.37,  0.22]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.06,  0.11,  0.24,  0.40,  0.18]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.05,  0.15,  0.26,  0.34,  0.20]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.07,  0.13,  0.28,  0.37,  0.15]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.04,  0.14,  0.25,  0.37,  0.20]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.03,  0.11,  0.28,  0.37,  0.21]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.01,  0.08,  0.19,  0.47,  0.26]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.02,  0.09,  0.27,  0.37,  0.24]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.04,  0.11,  0.25,  0.30,  0.31]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.05,  0.13,  0.24,  0.39,  0.18]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.04,  0.08,  0.33,  0.50,  0.04]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.02,  0.09,  0.24,  0.34,  0.31]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.02,  0.15,  0.29,  0.31,  0.23]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.05,  0.12,  0.27,  0.38,  0.18]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.04,  0.12,  0.22,  0.39,  0.23]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.04,  0.12,  0.26,  0.36,  0.22]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.06,  0.14,  0.27,  0.37,  0.15]
              },
            ],
            "questionName": "In your current employment situation, how satisfied are you with enjoying your day-to-day work (e.g., good coworkers/managers, pleasant work environment, manageable stress level).",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 27,
            "labels" : ["1 = Not at all satisfied", "2", "3","4", "5 = Extremely Satisfied"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.05,  0.08,  0.21,  0.40,  0.26]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.06,  0.11,  0.21,  0.38,  0.24]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.05,  0.08,  0.22,  0.39,  0.26]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.04,  0.06,  0.21,  0.40,  0.29]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.03,  0.07,  0.22,  0.41,  0.27]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.04,  0.08,  0.23,  0.38,  0.27]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.04,  0.11,  0.22,  0.39,  0.24]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.07,  0.05,  0.24,  0.41,  0.23]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.04,  0.09,  0.18,  0.40,  0.28]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.02,  0.11,  0.19,  0.40,  0.29]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.01,  0.08,  0.23,  0.43,  0.26]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.02,  0.06,  0.19,  0.48,  0.26]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.04,  0.08,  0.23,  0.36,  0.29]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.04,  0.09,  0.22,  0.39,  0.26]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  0.08,  0.21,  0.50,  0.21]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.03,  0.06,  0.20,  0.41,  0.31]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00,  0.08,  0.29,  0.40,  0.23]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.05,  0.13,  0.20,  0.39,  0.22]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.03,  0.07,  0.20,  0.38,  0.32]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.03,  0.06,  0.21,  0.41,  0.29]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.06,  0.09,  0.23,  0.40,  0.22]
              },
            ],
            "questionName": "In your current employment situation, how satisfied are you with having a sense of purpose and dignity in your work.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 28,
            "labels" : ["1 = Not at all satisfied", "2", "3","4", "5 = Extremely Satisfied"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.18,  0.23,  0.30,  0.21,  0.08]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.22,  0.26,  0.30,  0.16,  0.06]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.18,  0.24,  0.30,  0.21,  0.07]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.15,  0.21,  0.33,  0.23,  0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.13,  0.23,  0.32,  0.21,  0.10]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.18,  0.26,  0.29,  0.21,  0.06]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.21,  0.24,  0.29,  0.20,  0.06]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.21,  0.25,  0.30,  0.18,  0.06]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.17,  0.26,  0.29,  0.21,  0.07]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.16,  0.19,  0.35,  0.18,  0.12]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.11,  0.13,  0.33,  0.31,  0.12]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.11,  0.20,  0.37,  0.20,  0.13]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.17,  0.23,  0.29,  0.20,  0.10]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.16,  0.25,  0.29,  0.24,  0.06]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.04,  0.29,  0.33,  0.21,  0.13]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.12,  0.19,  0.35,  0.21,  0.12]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.17,  0.25,  0.38,  0.19,  0.02]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.23,  0.18,  0.28,  0.25,  0.07]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.14,  0.21,  0.27,  0.27,  0.11]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.18,  0.25,  0.25,  0.26,  0.07]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.21,  0.26,  0.31,  0.16,  0.05]
              },
            ],
            "questionName": "In your current employment situation, how satisfied are you with having the power to change things about your job that you’re not satisfied with.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 29,
            "labels" : ["All", "Men", "Not Men", "White","Not White"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.68, 1.00, 0.98, 1.00, 0.98]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.69, 1.00, 0.99, 1.00, 1.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.68, 1.00, 0.98, 1.00, 0.98]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.71, 1.00, 0.98, 1.00, 1.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.69, 1.00, 0.99, 1.00, 0.97]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.68, 1.00, 1.01, 1.00, 0.99]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.66, 1.00, 0.96, 1.00, 1.01]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.67, 1.00, 0.96, 1.00, 0.95]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.68, 1.00, 0.99, 1.00, 1.02]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.72, 1.00, 1.02, 1.00, 0.94]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.71, 1.00, 0.94, 1.00, 1.01]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.69, 1.00, 0.94, 1.00, 0.96]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.69, 1.00, 0.94, 1.00, 1.01]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.69, 1.00, 1.00, 1.00, 0.99]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.70, 1.00, 1.08, 1.00, 1.08]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.71, 1.00, 0.97, 1.00, 0.98]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.69, 1.00, 1.05, 1.00, 1.01]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.67, 1.00, 0.95, 1.00, 0.95]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.70, 1.00, 0.94, 1.00, 1.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.68, 1.00, 0.96, 1.00, 0.98]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.67, 1.00, 0.99, 1.00, 0.96]
              },
            ],
            "questionName": "Mean institutional satisfaction score (includes the previous ten satisfaction questions)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 30,
            "labels" : [ "Content",  "Worried",  "Excited",  "Sad",  "Connected to others",  "Bored",  "Angry",  "Hopeful",  "Disappointed",  "Inspired",  "None of the above",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.34,  0.36,  0.29,  0.09,  0.42,  0.13,  0.15,  0.34,  0.38,  0.28,  0.02]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.33,  0.34,  0.25,  0.12,  0.37,  0.13,  0.18,  0.35,  0.42,  0.24,  0.03]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.35,  0.36,  0.27,  0.10,  0.41,  0.13,  0.15,  0.33,  0.38,  0.28,  0.02]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.39,  0.38,  0.33,  0.07,  0.37,  0.11,  0.14,  0.35,  0.29,  0.32,  0.03]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.38,  0.34,  0.32,  0.08,  0.42,  0.13,  0.14,  0.34,  0.36,  0.29,  0.02]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.30,  0.35,  0.30,  0.10,  0.46,  0.12,  0.19,  0.34,  0.38,  0.32,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.30,  0.37,  0.24,  0.11,  0.44,  0.13,  0.14,  0.34,  0.38,  0.28,  0.03]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.32,  0.39,  0.28,  0.11,  0.38,  0.12,  0.17,  0.32,  0.42,  0.24,  0.02]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.31,  0.38,  0.30,  0.09,  0.38,  0.14,  0.14,  0.36,  0.39,  0.27,  0.03]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.42,  0.32,  0.30,  0.05,  0.49,  0.11,  0.10,  0.31,  0.33,  0.36,  0.03]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.48,  0.34,  0.28,  0.06,  0.47,  0.16,  0.13,  0.28,  0.27,  0.31,  0.03]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.34,  0.33,  0.46,  0.07,  0.42,  0.11,  0.12,  0.39,  0.27,  0.32,  0.02]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.37,  0.31,  0.31,  0.08,  0.43,  0.11,  0.16,  0.36,  0.33,  0.32,  0.02]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.31,  0.36,  0.29,  0.10,  0.43,  0.13,  0.15,  0.37,  0.38,  0.30,  0.01]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.17,  0.46,  0.33,  0.13,  0.21,  0.08,  0.21,  0.46,  0.38,  0.46,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.40,  0.35,  0.38,  0.07,  0.41,  0.10,  0.13,  0.37,  0.28,  0.32,  0.02]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.29,  0.48,  0.29,  0.06,  0.42,  0.15,  0.13,  0.33,  0.40,  0.29,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.32,  0.37,  0.29,  0.08,  0.47,  0.15,  0.19,  0.30,  0.38,  0.27,  0.01]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.35,  0.35,  0.32,  0.08,  0.48,  0.11,  0.13,  0.35,  0.32,  0.31,  0.02]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.32,  0.34,  0.30,  0.06,  0.44,  0.12,  0.12,  0.36,  0.37,  0.34,  0.02]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.34,  0.36,  0.23,  0.12,  0.38,  0.15,  0.17,  0.31,  0.44,  0.24,  0.02]
              },
            ],
            "questionName": "Thinking about the past 12 months in your workplace (or your total tenure if less than 12 months), which of the following 3 emotions do you most associate with working at your museum? Select up to THREE.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 31,
            "labels" : ["Yes","No","Prefer Not To Answer", "Men", "Not Men","White","Not White"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.24, 0.70, 0.07, 1.00, 1.55, 1.00, 1.21]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.27, 0.66, 0.07, 1.00, 1.32, 1.00, 1.24]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.24, 0.69, 0.07, 1.00, 1.50, 1.00, 1.23]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.22, 0.69, 0.08, 1.00, 1.17, 1.00, 1.25]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.22, 0.73, 0.06, 1.00, 1.76, 1.00, 1.32]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.23, 0.71, 0.06, 1.00, 1.34, 1.00, 1.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.24, 0.69, 0.07, 1.00, 1.43, 1.00, 1.32]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.25, 0.68, 0.07, 1.00, 1.72, 1.00, 1.25]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.24, 0.69, 0.08, 1.00, 1.74, 1.00, 1.33]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.21, 0.73, 0.06, 1.00, 1.12, 1.00, 1.86]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.22, 0.72, 0.07, 1.00, 3.16, 1.00, 1.69]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.21, 0.74, 0.04, 1.00, 2.07, 1.00, 1.19]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.21, 0.69, 0.10, 1.00, 1.64, 1.00, 1.40]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.23, 0.73, 0.04, 1.00, 1.18, 1.00, 0.74]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.21, 0.75, 0.04, 1.00, 0.80, 1.00, 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.19, 0.74, 0.07, 1.00, 1.24, 1.00, 1.25]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.29, 0.67, 0.04, 1.00, 0.73, 1.00, 1.09]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.28, 0.64, 0.08, 1.00, 1.55, 1.00, 1.28]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.18, 0.75, 0.07, 1.00, 2.37, 1.00, 1.30]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.25, 0.70, 0.06, 1.00, 1.86, 1.00, 1.26]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.26, 0.67, 0.07, 1.00, 1.49, 1.00, 1.25]
              },
            ],
            "questionName": "Have you felt discriminated against or harassed on the basis your gender, sexual orientation, racial or ethnic background, social or economic status, religion, age, or disability while working in your current museum workplace?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
        ]
      },
      {
        section_title: "The following five questions were only asked of staff who have experienced discrimination or harassment in their current workplace.",
        charts: [
          {
            "id": 32,
            "labels" : ["Very frequently (e.g., daily or almost daily)","Often (e.g., a few times a month)","Sometimes (e.g., a few times a year) ","Rarely (e.g., one or two times total)"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.05,  0.17,  0.48,  0.30]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.06,  0.27,  0.40,  0.26]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.05,  0.18,  0.46,  0.30]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.05,  0.10,  0.48,  0.36]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.04,  0.14,  0.51,  0.32]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.04,  0.23,  0.44,  0.30]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.04,  0.22,  0.44,  0.30]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.06,  0.21,  0.46,  0.27]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.04,  0.17,  0.49,  0.30]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.00,  0.13,  0.53,  0.34]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.11,  0.11,  0.46,  0.32]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.03,  0.15,  0.48,  0.35]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.05,  0.20,  0.42,  0.32]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.03,  0.18,  0.44,  0.35]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  0.17,  0.50,  0.33]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.06,  0.09,  0.52,  0.33]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00,  0.14,  0.36,  0.50]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.05,  0.18,  0.38,  0.40]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.07,  0.18,  0.43,  0.32]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.03,  0.14,  0.56,  0.27]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.05,  0.23,  0.45,  0.27]
              },
            ],
            "questionName": "How often have you felt discriminated against?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 33,
            "labels" : ["Discrimination and/or harassment based on gender (including pregnancy, gender expression, gender identity, etc.)","Discrimination and/or harassment based on sexual orientation","Discrimination and/or harassment based on race and/or ethnicity","Discrimination and/or harassment based on social or economic status","Discrimination and/or harassment based on religion","Discrimination and/or harassment based on age","Discrimination and/or harassment based on disability","Another form of discrimination and/or harassment","I don’t know ",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.52,  0.11,  0.33,  0.29,  0.05,  0.40,  0.13,  0.21,  0.01]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.50,  0.09,  0.34,  0.29,  0.05,  0.46,  0.09,  0.22,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.53,  0.12,  0.31,  0.28,  0.05,  0.40,  0.13,  0.21,  0.01]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.57,  0.11,  0.23,  0.27,  0.11,  0.41,  0.18,  0.14,  0.04]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.52,  0.13,  0.33,  0.31,  0.04,  0.36,  0.11,  0.21,  0.01]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.50,  0.13,  0.43,  0.26,  0.04,  0.44,  0.09,  0.25,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.43,  0.09,  0.31,  0.26,  0.06,  0.33,  0.16,  0.26,  0.01]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.56,  0.12,  0.39,  0.29,  0.03,  0.44,  0.07,  0.24,  0.01]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.50,  0.04,  0.26,  0.24,  0.06,  0.39,  0.18,  0.28,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.50,  0.05,  0.34,  0.30,  0.09,  0.43,  0.18,  0.14,  0.00]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.52,  0.15,  0.15,  0.37,  0.07,  0.30,  0.30,  0.22,  0.04]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.36,  0.05,  0.46,  0.33,  0.08,  0.31,  0.13,  0.28,  0.03]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.48,  0.14,  0.39,  0.29,  0.07,  0.30,  0.20,  0.16,  0.00]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.49,  0.16,  0.26,  0.27,  0.06,  0.36,  0.09,  0.19,  0.03]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.50,  0.00,  0.17,  0.50,  0.00,  0.67,  0.50,  0.00,  0.00]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.48,  0.10,  0.33,  0.30,  0.08,  0.35,  0.14,  0.19,  0.05]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.57,  0.14,  0.36,  0.36,  0.00,  0.36,  0.14,  0.07,  0.00]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.42,  0.16,  0.24,  0.32,  0.13,  0.24,  0.24,  0.29,  0.00]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.45,  0.08,  0.27,  0.23,  0.06,  0.35,  0.17,  0.32,  0.00]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.48,  0.09,  0.40,  0.24,  0.01,  0.40,  0.19,  0.22,  0.03]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.57,  0.12,  0.32,  0.29,  0.05,  0.43,  0.07,  0.19,  0.00]
              },
            ],
            "questionName": "Which of the following forms of discrimination and/or harassment, have you experienced in your current museum workplace? Please select all that apply.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 34,
            "labels" : ["I filed an HR complaint form (i.e., in-person or online)","I talked to an HR staff member who is available to employees","I used an anonymous reporting mechanism","I used an employee complaint hotline","I talked to a neutral employee or manager who can communicate the issues to HR","I used a third-party reporting process (e.g., use of an ombudsman)","I used another reporting mechanism","I followed a union-provided grievance process for reporting","I did something else","I haven’t done anything in response",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.11,  0.29,  0.05,  0.01,  0.26,  0.03,  0.05,  0.00,  0.21,  0.47]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.13,  0.32,  0.03,  0.02,  0.28,  0.01,  0.03,  0.00,  0.21,  0.46]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.12,  0.28,  0.04,  0.01,  0.26,  0.03,  0.04,  0.00,  0.22,  0.47]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.07,  0.16,  0.04,  0.00,  0.11,  0.09,  0.05,  0.00,  0.23,  0.55]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.06,  0.31,  0.06,  0.00,  0.23,  0.03,  0.06,  0.01,  0.19,  0.49]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.18,  0.37,  0.01,  0.00,  0.34,  0.01,  0.07,  0.00,  0.27,  0.37]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.18,  0.32,  0.04,  0.01,  0.31,  0.00,  0.03,  0.00,  0.19,  0.43]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.09,  0.29,  0.06,  0.01,  0.27,  0.03,  0.07,  0.00,  0.24,  0.45]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.07,  0.27,  0.07,  0.01,  0.27,  0.00,  0.05,  0.01,  0.24,  0.47]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.09,  0.26,  0.02,  0.00,  0.14,  0.00,  0.05,  0.00,  0.12,  0.65]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.15,  0.35,  0.00,  0.00,  0.15,  0.12,  0.00,  0.00,  0.19,  0.54]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.03,  0.41,  0.05,  0.00,  0.26,  0.05,  0.10,  0.00,  0.13,  0.46]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.17,  0.24,  0.02,  0.00,  0.29,  0.02,  0.03,  0.00,  0.22,  0.41]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.16,  0.37,  0.03,  0.00,  0.29,  0.05,  0.02,  0.00,  0.20,  0.42]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.17,  0.17,  0.00,  0.00,  0.33,  0.17,  0.00,  0.00,  0.50,  0.17]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.05,  0.27,  0.03,  0.00,  0.16,  0.03,  0.06,  0.00,  0.17,  0.55]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.00,  0.29,  0.00,  0.00,  0.21,  0.07,  0.00,  0.00,  0.14,  0.50]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.22,  0.36,  0.06,  0.00,  0.39,  0.06,  0.03,  0.00,  0.17,  0.39]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.16,  0.33,  0.05,  0.00,  0.21,  0.06,  0.06,  0.00,  0.21,  0.41]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.08,  0.35,  0.05,  0.00,  0.33,  0.00,  0.05,  0.00,  0.29,  0.42]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.12,  0.27,  0.05,  0.02,  0.26,  0.02,  0.05,  0.01,  0.21,  0.49]
              },
            ],
            "questionName": "Have you taken any of the following actions in response to discrimination and/or harassment in your current museum workplace? Please select ALL that apply.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 35,
            "labels" : ["Not at all satisfied","Somewhat satisfied","Very satisfied"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.54,  0.38,  0.08]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.55,  0.35,  0.09]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.56,  0.35,  0.09]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.61,  0.22,  0.17]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.47,  0.41,  0.13]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.32,  0.56,  0.12]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.58,  0.36,  0.06]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.57,  0.38,  0.05]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.65,  0.26,  0.09]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.27,  0.53,  0.20]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.73,  0.18,  0.09]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.36,  0.55,  0.09]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.50,  0.29,  0.21]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.47,  0.51,  0.02]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.50,  0.00,  0.50]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.46,  0.38,  0.15]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.20,  0.80,  0.00]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.75,  0.25,  0.00]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.34,  0.46,  0.20]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.51,  0.43,  0.06]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.62,  0.33,  0.05]
              },
            ],
            "questionName": "How satisfied are you with how HR and /or the museum resolved your complaint(s) overall?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 36,
            "labels" : ["I worried about retaliation from people in leadership at the institution","I worried about retaliation from the person who discriminated against/harassed me","I didn’t think anything would be done about it","I didn’t think anything could be done about it because the person who discriminated against/harassed me is not an employee of the museum (e.g., visitor, board member, artist...)","I didn’t know about what actions I could take","My workplace doesn’t provide any mechanisms to report discrimination and/or harassment","I always reported my experiences of discrimination and harassment","I haven't experienced discrimination or harassment"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.46,  0.47,  0.70,  0.15,  0.19,  0.11,  0.10,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.56,  0.57,  0.71,  0.13,  0.14,  0.04,  0.09,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.47,  0.48,  0.69,  0.14,  0.18,  0.08,  0.10,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.38,  0.38,  0.72,  0.17,  0.26,  0.09,  0.08,  0.02]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.46,  0.45,  0.72,  0.15,  0.21,  0.19,  0.08,  0.01]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.42,  0.52,  0.60,  0.17,  0.06,  0.03,  0.18,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.44,  0.44,  0.68,  0.19,  0.13,  0.04,  0.12,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.52,  0.51,  0.73,  0.09,  0.19,  0.09,  0.10,  0.01]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.51,  0.55,  0.76,  0.20,  0.09,  0.12,  0.06,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.48,  0.50,  0.70,  0.11,  0.20,  0.14,  0.09,  0.00]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.58,  0.42,  0.69,  0.15,  0.15,  0.12,  0.04,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.39,  0.42,  0.74,  0.24,  0.24,  0.26,  0.13,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.36,  0.36,  0.64,  0.22,  0.29,  0.11,  0.15,  0.00]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.34,  0.40,  0.60,  0.16,  0.19,  0.07,  0.14,  0.01]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.20,  0.40,  0.60,  0.20,  0.60,  0.00,  0.00,  0.00]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.34,  0.37,  0.69,  0.18,  0.24,  0.19,  0.11,  0.02]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.21,  0.36,  0.71,  0.36,  0.29,  0.29,  0.07,  0.00]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.50,  0.47,  0.67,  0.17,  0.22,  0.17,  0.14,  0.00]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.52,  0.41,  0.70,  0.16,  0.11,  0.05,  0.08,  0.00]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.41,  0.48,  0.65,  0.17,  0.15,  0.05,  0.11,  0.00]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.52,  0.53,  0.71,  0.10,  0.17,  0.08,  0.10,  0.01]
              },
            ],
            "questionName": "If you have experienced any discrimination or harassment and decided NOT to take action in response, what were your reasons? Select all that apply.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 37,
            "labels" : ["Yes","No"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.60,  0.40]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.59,  0.41]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.59,  0.41]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.52,  0.48]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.59,  0.41]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.61,  0.39]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.59,  0.41]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.66,  0.34]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.59,  0.41]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.55,  0.45]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.53,  0.47]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.61,  0.39]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.51,  0.49]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.57,  0.43]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.67,  0.33]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.54,  0.46]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.58,  0.42]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.63,  0.37]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.51,  0.49]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.57,  0.43]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.64,  0.36]
              },
            ],
            "questionName": "Have you ever considered leaving your current museum workplace for another art museum?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 38,
            "allowOverlap" : true,
            "labels" : ["Pay is too low","Other institutions have more flexible work hours","No full-time work is available in this institution","Opportunities for growth at the other museum","Experiences of discrimination or harassment","Lack of opportunities for growth at my museum","Unsafe working conditions","Positive reputation of the other museum","Burnout","Desire to live in a different town or city","Interpersonal issues with other staff members","Poor management","I don’t believe my institution can change for the better","Personal reasons unrelated to my current museum workplace","None of the above",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.61,  0.09,  0.07,  0.54,  0.14,  0.51,  0.05,  0.22,  0.59,  0.28,  0.24,  0.46,  0.25,  0.19,  0.01]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.45,  0.14,  0.05,  0.56,  0.18,  0.54,  0.06,  0.23,  0.58,  0.30,  0.25,  0.58,  0.30,  0.15,  0.02]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.60,  0.10,  0.07,  0.53,  0.14,  0.52,  0.05,  0.22,  0.58,  0.28,  0.25,  0.47,  0.26,  0.20,  0.01]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.54,  0.11,  0.08,  0.49,  0.14,  0.44,  0.03,  0.22,  0.63,  0.26,  0.28,  0.42,  0.21,  0.22,  0.01]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.69,  0.06,  0.09,  0.54,  0.12,  0.50,  0.03,  0.23,  0.56,  0.32,  0.22,  0.41,  0.22,  0.23,  0.01]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.64,  0.09,  0.07,  0.58,  0.14,  0.53,  0.06,  0.20,  0.58,  0.26,  0.30,  0.45,  0.19,  0.21,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.66,  0.11,  0.06,  0.55,  0.14,  0.53,  0.05,  0.21,  0.61,  0.30,  0.25,  0.48,  0.30,  0.16,  0.01]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.60,  0.10,  0.07,  0.55,  0.16,  0.53,  0.03,  0.24,  0.55,  0.22,  0.24,  0.47,  0.25,  0.18,  0.01]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.63,  0.09,  0.07,  0.54,  0.11,  0.56,  0.06,  0.23,  0.62,  0.27,  0.25,  0.50,  0.21,  0.16,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.60,  0.12,  0.09,  0.58,  0.14,  0.47,  0.04,  0.27,  0.53,  0.50,  0.27,  0.49,  0.27,  0.25,  0.02]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.64,  0.05,  0.06,  0.50,  0.13,  0.38,  0.05,  0.27,  0.53,  0.34,  0.20,  0.28,  0.23,  0.23,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.69,  0.07,  0.09,  0.59,  0.13,  0.44,  0.06,  0.23,  0.61,  0.28,  0.26,  0.35,  0.15,  0.21,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.64,  0.08,  0.10,  0.54,  0.12,  0.53,  0.04,  0.17,  0.62,  0.30,  0.23,  0.44,  0.26,  0.20,  0.02]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.61,  0.11,  0.04,  0.49,  0.14,  0.49,  0.06,  0.17,  0.63,  0.27,  0.27,  0.43,  0.24,  0.22,  0.01]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.56,  0.19,  0.00,  0.38,  0.13,  0.19,  0.13,  0.25,  0.81,  0.25,  0.50,  0.50,  0.13,  0.31,  0.06]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.60,  0.09,  0.10,  0.54,  0.11,  0.45,  0.04,  0.21,  0.57,  0.27,  0.25,  0.32,  0.19,  0.19,  0.00]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.61,  0.07,  0.07,  0.39,  0.11,  0.54,  0.00,  0.21,  0.71,  0.32,  0.14,  0.57,  0.11,  0.18,  0.04]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.76,  0.06,  0.05,  0.49,  0.15,  0.55,  0.06,  0.22,  0.76,  0.34,  0.23,  0.44,  0.23,  0.22,  0.01]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.67,  0.07,  0.07,  0.49,  0.16,  0.45,  0.06,  0.25,  0.53,  0.28,  0.21,  0.45,  0.29,  0.24,  0.02]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.63,  0.06,  0.08,  0.54,  0.09,  0.51,  0.04,  0.15,  0.54,  0.23,  0.31,  0.34,  0.15,  0.19,  0.01]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.57,  0.12,  0.06,  0.57,  0.16,  0.56,  0.04,  0.24,  0.58,  0.29,  0.24,  0.53,  0.29,  0.18,  0.01]
              },
            ],
            "questionName": "Which of the following reasons made you consider leaving your current museum workplace for another art museum? Select all that apply.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 39,
            "labels" : ["Yes","No"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.68,  0.32]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.65,  0.35]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.68,  0.32]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.66,  0.34]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.70,  0.30]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.67,  0.33]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.67,  0.33]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.69,  0.31]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.68,  0.32]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.67,  0.33]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.69,  0.31]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.65,  0.35]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.63,  0.37]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.70,  0.30]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.54,  0.46]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.65,  0.35]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.65,  0.35]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.75,  0.25]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.64,  0.36]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.68,  0.32]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.70,  0.30]
              },
            ],
            "questionName": "Have you ever considered leaving the art museum field?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 40,
            "allowOverlap" : true,
            "labels" : ["Pay is too low in art museums","Other fields have more flexible work hours","More interested in other fields","Full-time work is unavailable to me in most art museums","Experiences of discrimination or harassment in art museums","Lack of opportunities for growth in art museums","Unsafe working conditions in art museums","Burnout in the art museum field","Exciting opportunities in other fields","Interpersonal issues with other staff members are common in art museums","Poor management in art museums","I don’t believe art museums can change for the better","Personal reasons unrelated to art and/or museums","None of the above",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.68,  0.22,  0.19,  0.10,  0.11,  0.47,  0.04,  0.54,  0.35,  0.16,  0.41,  0.15,  0.29,  0.02]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.63,  0.27,  0.16,  0.07,  0.14,  0.54,  0.05,  0.49,  0.36,  0.22,  0.47,  0.15,  0.28,  0.01]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.68,  0.22,  0.19,  0.10,  0.10,  0.47,  0.04,  0.52,  0.35,  0.16,  0.40,  0.15,  0.29,  0.02]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.56,  0.17,  0.24,  0.13,  0.07,  0.42,  0.02,  0.55,  0.38,  0.11,  0.31,  0.08,  0.28,  0.02]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.71,  0.18,  0.20,  0.11,  0.08,  0.43,  0.03,  0.55,  0.34,  0.13,  0.36,  0.12,  0.31,  0.02]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.74,  0.26,  0.20,  0.09,  0.13,  0.46,  0.04,  0.59,  0.32,  0.20,  0.45,  0.12,  0.32,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.70,  0.24,  0.19,  0.10,  0.12,  0.49,  0.04,  0.52,  0.37,  0.17,  0.45,  0.17,  0.30,  0.02]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.66,  0.22,  0.16,  0.09,  0.12,  0.47,  0.04,  0.55,  0.34,  0.16,  0.41,  0.18,  0.25,  0.02]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.64,  0.20,  0.20,  0.12,  0.10,  0.47,  0.03,  0.54,  0.39,  0.16,  0.40,  0.09,  0.30,  0.01]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.70,  0.22,  0.19,  0.10,  0.07,  0.48,  0.04,  0.56,  0.35,  0.17,  0.42,  0.16,  0.34,  0.01]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.64,  0.15,  0.14,  0.12,  0.12,  0.42,  0.04,  0.44,  0.37,  0.11,  0.26,  0.15,  0.33,  0.05]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.63,  0.16,  0.21,  0.08,  0.13,  0.45,  0.04,  0.63,  0.42,  0.15,  0.36,  0.10,  0.36,  0.02]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.66,  0.15,  0.26,  0.11,  0.07,  0.48,  0.02,  0.48,  0.34,  0.12,  0.35,  0.10,  0.34,  0.02]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.73,  0.27,  0.22,  0.08,  0.13,  0.49,  0.05,  0.56,  0.37,  0.17,  0.46,  0.14,  0.30,  0.01]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.62,  0.23,  0.31,  0.00,  0.08,  0.38,  0.00,  0.77,  0.46,  0.15,  0.38,  0.08,  0.15,  0.00]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.57,  0.18,  0.21,  0.13,  0.09,  0.43,  0.04,  0.53,  0.33,  0.18,  0.30,  0.09,  0.31,  0.02]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.68,  0.13,  0.32,  0.03,  0.10,  0.45,  0.00,  0.65,  0.42,  0.16,  0.39,  0.10,  0.26,  0.03]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.72,  0.13,  0.19,  0.09,  0.14,  0.52,  0.04,  0.59,  0.42,  0.11,  0.39,  0.16,  0.35,  0.01]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.72,  0.17,  0.20,  0.11,  0.09,  0.41,  0.04,  0.52,  0.30,  0.13,  0.43,  0.13,  0.33,  0.02]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.74,  0.21,  0.19,  0.11,  0.13,  0.45,  0.06,  0.53,  0.35,  0.15,  0.35,  0.18,  0.31,  0.02]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.67,  0.27,  0.18,  0.09,  0.11,  0.52,  0.03,  0.52,  0.38,  0.17,  0.44,  0.16,  0.27,  0.02]
              },
            ],
            "questionName": "Which of the following reasons made you consider leaving the art museum field? Select all that apply.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 41,
            "labels" : ["I personally incorporate principles of diversity, equity, and inclusion in my overall work","I am currently a member of a diversity, equity, and inclusion committee at work","I was previously a member of a diversity, equity, and inclusion committee at work","My position specifically focuses on diversity, equity, and inclusion","None of the above – I am not involved in my museum’s diversity, equity, and inclusion efforts","None of the above – I am not clear about how to get involved in my museum’s diversity, equity, and inclusion efforts","Not applicable – my museum does not have any efforts towards diversity, equity, and inclusion"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.68,  0.18,  0.11,  0.07,  0.19,  0.11,  0.03]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.67,  0.15,  0.12,  0.07,  0.20,  0.13,  0.02]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.67,  0.17,  0.12,  0.07,  0.20,  0.11,  0.02]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.71,  0.16,  0.13,  0.11,  0.18,  0.08,  0.02]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.66,  0.19,  0.09,  0.07,  0.21,  0.12,  0.03]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.71,  0.18,  0.14,  0.08,  0.14,  0.11,  0.04]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.68,  0.18,  0.12,  0.07,  0.18,  0.13,  0.03]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.66,  0.21,  0.12,  0.07,  0.21,  0.10,  0.01]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.73,  0.15,  0.09,  0.08,  0.17,  0.12,  0.02]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.59,  0.12,  0.09,  0.06,  0.24,  0.16,  0.07]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.72,  0.25,  0.09,  0.08,  0.17,  0.05,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.64,  0.23,  0.07,  0.09,  0.18,  0.12,  0.06]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.66,  0.15,  0.06,  0.11,  0.20,  0.14,  0.02]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.67,  0.16,  0.18,  0.05,  0.18,  0.12,  0.04]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.67,  0.21,  0.04,  0.21,  0.17,  0.08,  0.00]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.65,  0.18,  0.09,  0.09,  0.18,  0.11,  0.04]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.63,  0.21,  0.17,  0.10,  0.19,  0.10,  0.02]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.67,  0.25,  0.12,  0.08,  0.18,  0.12,  0.02]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.68,  0.19,  0.07,  0.07,  0.19,  0.11,  0.04]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.70,  0.17,  0.17,  0.07,  0.19,  0.09,  0.01]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.67,  0.15,  0.12,  0.06,  0.21,  0.13,  0.03]
              },
            ],
            "questionName": "What kind of role, if any, do you have in your museum’s diversity, equity, and inclusion efforts? Select all that apply.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
        ]
      },
      {
        section_title: "PART 4: Workplace climate & Organizational characteristics",
        charts: [
          {
            "id": 42,
            "labels" : ["Artists in the collection","Board and/or trustee members","Staff members","Volunteers","None of the above","I don’t know"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.49,  0.45,  0.52,  0.15,  0.03,  0.38]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.54,  0.44,  0.54,  0.17,  0.02,  0.36]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.51,  0.44,  0.51,  0.16,  0.02,  0.39]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.53,  0.41,  0.48,  0.20,  0.03,  0.36]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.50,  0.50,  0.54,  0.10,  0.03,  0.36]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.40,  0.47,  0.59,  0.18,  0.02,  0.35]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.41,  0.36,  0.46,  0.14,  0.03,  0.46]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.52,  0.50,  0.55,  0.12,  0.03,  0.36]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.55,  0.47,  0.54,  0.22,  0.03,  0.33]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.40,  0.41,  0.43,  0.09,  0.02,  0.45]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.42,  0.29,  0.40,  0.09,  0.05,  0.49]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.26,  0.52,  0.53,  0.07,  0.04,  0.34]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.52,  0.38,  0.47,  0.16,  0.02,  0.42]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.42,  0.45,  0.56,  0.17,  0.02,  0.37]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.58,  0.33,  0.42,  0.21,  0.04,  0.38]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.41,  0.43,  0.47,  0.13,  0.05,  0.38]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.33,  0.42,  0.35,  0.06,  0.00,  0.48]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.52,  0.47,  0.55,  0.24,  0.01,  0.38]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.42,  0.39,  0.47,  0.16,  0.02,  0.46]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.46,  0.48,  0.57,  0.17,  0.02,  0.36]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.56,  0.47,  0.54,  0.13,  0.03,  0.35]
              },
            ],
            "questionName": "Has your museum measured the composition of any of the following groups?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 43,
            "labels" : ["Yes, I am a union member for my museum job","No, I have the option to be a union member for my museum job but have not chosen to join","No, a union is not available for my museum job"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.13,  0.03,  0.84]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.09,  0.04,  0.87]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.14,  0.03,  0.83]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.09,  0.02,  0.89]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.19,  0.03,  0.78]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.24,  0.02,  0.73]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.07,  0.02,  0.91]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.20,  0.04,  0.76]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.11,  0.02,  0.87]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.00,  0.02,  0.97]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.11,  0.01,  0.88]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.06,  0.02,  0.93]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.02,  0.02,  0.95]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.20,  0.02,  0.78]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.08,  0.00,  0.92]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.01,  0.02,  0.97]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.02,  0.02,  0.96]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.15,  0.01,  0.84]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.09,  0.02,  0.89]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.10,  0.02,  0.88]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.22,  0.04,  0.74]
              },
            ],
            "questionName": "Are you a member, or do you have the option of being a member, of a union as part of your museum job?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 44,
            "labels" : ["I am/will be working in-person only","I am/will be working in a hybrid setup where the museum chooses how many and which days to work from home and which days to work from the museum","I am/will be working in a hybrid setup where I get to choose how many and which days to work from home and which days to work in the museum","I am/will be working in a hybrid setup where the museum chooses how many days to work from home and how many days to work from the museum and I get to choose which days","I am/will be working from home only","I don’t know much about the museum’s return-to-work plans"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.37,  0.17,  0.22,  0.33,  0.01,  0.03]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.35,  0.17,  0.16,  0.42,  0.00,  0.01]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.39,  0.16,  0.21,  0.32,  0.02,  0.03]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.51,  0.17,  0.27,  0.16,  0.03,  0.03]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.38,  0.18,  0.20,  0.31,  0.02,  0.03]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.25,  0.18,  0.32,  0.34,  0.02,  0.03]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.39,  0.18,  0.17,  0.36,  0.00,  0.03]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.30,  0.21,  0.19,  0.41,  0.02,  0.02]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.39,  0.15,  0.21,  0.36,  0.01,  0.02]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.42,  0.13,  0.17,  0.32,  0.01,  0.03]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.47,  0.07,  0.30,  0.21,  0.03,  0.03]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.27,  0.24,  0.27,  0.33,  0.01,  0.03]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.53,  0.20,  0.27,  0.12,  0.01,  0.05]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.35,  0.16,  0.23,  0.33,  0.02,  0.04]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.67,  0.29,  0.29,  0.00,  0.00,  0.00]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.44,  0.18,  0.24,  0.24,  0.02,  0.03]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.42,  0.31,  0.25,  0.15,  0.02,  0.08]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.38,  0.20,  0.27,  0.29,  0.03,  0.02]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.49,  0.10,  0.30,  0.18,  0.00,  0.06]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.35,  0.18,  0.23,  0.31,  0.02,  0.03]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.31,  0.17,  0.15,  0.45,  0.01,  0.01]
              },
            ],
            "questionName": "Which of the following statements best represent your museum’s post-COVID return-to- work policy, as it applies to you? Select all that apply.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 45,
            "labels" : ["My workplace shares the specific salaries of all employees publicly","My union handbook lists salary levels for each job","My workplace shares salary ranges for all positions or levels with employees","My workplace posts salary ranges for each open position","My workplace actively discourages employees from discussing their salaries","None of the above ","I don’t know"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.12,  0.06,  0.11,  0.38,  0.17,  0.16,  0.22]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.22,  0.05,  0.10,  0.44,  0.12,  0.12,  0.18]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.14,  0.06,  0.11,  0.36,  0.17,  0.17,  0.23]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.34,  0.04,  0.12,  0.32,  0.13,  0.11,  0.25]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.03,  0.09,  0.09,  0.36,  0.19,  0.20,  0.25]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.05,  0.09,  0.10,  0.31,  0.15,  0.24,  0.26]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.16,  0.04,  0.12,  0.38,  0.17,  0.13,  0.22]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.10,  0.04,  0.14,  0.45,  0.14,  0.14,  0.22]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.31,  0.07,  0.07,  0.43,  0.13,  0.12,  0.18]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.01,  0.00,  0.04,  0.36,  0.25,  0.21,  0.24]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.05,  0.07,  0.19,  0.52,  0.13,  0.09,  0.20]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.02,  0.05,  0.17,  0.54,  0.11,  0.11,  0.21]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.11,  0.01,  0.05,  0.19,  0.22,  0.24,  0.34]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.08,  0.15,  0.16,  0.34,  0.13,  0.17,  0.22]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.71,  0.04,  0.17,  0.33,  0.13,  0.04,  0.13]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.11,  0.00,  0.15,  0.39,  0.13,  0.15,  0.26]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.00,  0.00,  0.02,  0.58,  0.13,  0.10,  0.23]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.17,  0.10,  0.15,  0.44,  0.18,  0.15,  0.15]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.05,  0.04,  0.05,  0.33,  0.22,  0.19,  0.27]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.06,  0.03,  0.21,  0.37,  0.12,  0.21,  0.21]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.17,  0.10,  0.09,  0.38,  0.16,  0.14,  0.22]
              },
            ],
            "questionName": "Which of the following statements best reflect the salary sharing practices of your museum workplace? Select all that apply",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 46,
            "labels" : ["Strongly Disagree","Somewhat Disagree","Neutral","Somewhat Agree","Strongly Agree","Prefer not to answer",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" :  true,
                "data" : [0.05,  0.12,  0.11,  0.38,  0.34,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" :  false,
                "data" : [0.06,  0.15,  0.11,  0.37,  0.30,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" :  false,
                "data" : [0.06,  0.12,  0.12,  0.37,  0.33,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" :  false,
                "data" : [0.04,  0.12,  0.09,  0.34,  0.40,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" :  false,
                "data" : [0.04,  0.08,  0.11,  0.40,  0.36,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" :  false,
                "data" : [0.04,  0.10,  0.12,  0.39,  0.34,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" :  false,
                "data" : [0.06,  0.14,  0.11,  0.38,  0.30,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" :  false,
                "data" : [0.07,  0.13,  0.13,  0.37,  0.30,  0.01]
              },
              {
                "name" : "Midwest",
                "visible" :  false,
                "data" : [0.06,  0.15,  0.11,  0.35,  0.32,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" :  false,
                "data" : [0.02,  0.09,  0.08,  0.41,  0.40,  0.00]
              },
              {
                "name" : "New England",
                "visible" :  false,
                "data" : [0.03,  0.06,  0.11,  0.38,  0.43,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" :  false,
                "data" : [0.02,  0.06,  0.08,  0.41,  0.43,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" :  false,
                "data" : [0.06,  0.11,  0.11,  0.33,  0.38,  0.00]
              },
              {
                "name" : "Western",
                "visible" :  false,
                "data" : [0.04,  0.11,  0.11,  0.42,  0.32,  0.00]
              },
              {
                "name" : "size1",
                "visible" :  false,
                "data" : [0.04,  0.08,  0.08,  0.21,  0.58,  0.00]
              },
              {
                "name" : "size2",
                "visible" :  false,
                "data" : [0.03,  0.09,  0.10,  0.34,  0.43,  0.00]
              },
              {
                "name" : "size3",
                "visible" :  false,
                "data" : [0.00,  0.04,  0.15,  0.52,  0.29,  0.00]
              },
              {
                "name" : "size4",
                "visible" :  false,
                "data" : [0.08,  0.11,  0.11,  0.37,  0.33,  0.00]
              },
              {
                "name" : "size5",
                "visible" :  false,
                "data" : [0.06,  0.10,  0.10,  0.36,  0.39,  0.00]
              },
              {
                "name" : "size6",
                "visible" :  false,
                "data" : [0.04,  0.14,  0.13,  0.36,  0.32,  0.01]
              },
              {
                "name" : "size7",
                "visible" :  false,
                "data" : [0.07,  0.14,  0.12,  0.40,  0.28,  0.00]
              },
            ],
            "questionName": "Please rate how much you agree or disagree with the following statements in relation to the culture of your current museum workplace: I believe that I can learn and grow in this organization",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 47,
            "labels" : ["Strongly Disagree","Somewhat Disagree","Neutral","Somewhat Agree","Strongly Agree","Prefer not to answer",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.13,  0.15,  0.19,  0.30,  0.22,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.14,  0.15,  0.19,  0.30,  0.22,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.13,  0.15,  0.20,  0.30,  0.22,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.18,  0.15,  0.15,  0.31,  0.21,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.15,  0.15,  0.20,  0.30,  0.20,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.12,  0.13,  0.21,  0.29,  0.24,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.12,  0.15,  0.22,  0.30,  0.22,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.10,  0.14,  0.20,  0.30,  0.25,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.15,  0.16,  0.16,  0.31,  0.22,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.19,  0.15,  0.20,  0.28,  0.17,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.10,  0.22,  0.25,  0.27,  0.16,  0.01]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.16,  0.16,  0.19,  0.29,  0.19,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.20,  0.16,  0.17,  0.28,  0.19,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.12,  0.13,  0.22,  0.31,  0.22,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.13,  0.08,  0.17,  0.42,  0.21,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.19,  0.17,  0.17,  0.30,  0.17,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.23,  0.08,  0.08,  0.33,  0.27,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.10,  0.13,  0.17,  0.32,  0.28,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.15,  0.19,  0.21,  0.26,  0.19,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.12,  0.17,  0.26,  0.25,  0.20,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.12,  0.12,  0.19,  0.33,  0.24,  0.00]
              },
            ],
            "questionName": "Please rate how much you agree or disagree with the following statements in relation to the culture of your current museum workplace: I feel burned out in this organization",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 48,
            "labels" : ["Strongly Disagree","Somewhat Disagree","Neutral","Somewhat Agree","Strongly Agree","Prefer not to answer",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.06,  0.07,  0.11,  0.30,  0.47,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.08,  0.08,  0.12,  0.29,  0.43,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.06,  0.07,  0.11,  0.29,  0.47,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.05,  0.06,  0.10,  0.27,  0.52,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.03,  0.07,  0.10,  0.30,  0.49,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.06,  0.07,  0.09,  0.29,  0.49,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.06,  0.07,  0.12,  0.30,  0.45,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.07,  0.08,  0.11,  0.31,  0.42,  0.01]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.06,  0.08,  0.12,  0.33,  0.42,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.03,  0.06,  0.12,  0.28,  0.50,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.01,  0.06,  0.08,  0.28,  0.58,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.02,  0.06,  0.08,  0.33,  0.51,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.05,  0.08,  0.10,  0.24,  0.53,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.06,  0.05,  0.09,  0.30,  0.50,  0.01]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.08,  0.04,  0.08,  0.29,  0.50,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.02,  0.06,  0.10,  0.29,  0.53,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.06,  0.08,  0.15,  0.25,  0.46,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.06,  0.06,  0.10,  0.30,  0.48,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.05,  0.05,  0.10,  0.26,  0.54,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.05,  0.10,  0.08,  0.29,  0.47,  0.01]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.07,  0.07,  0.12,  0.32,  0.41,  0.01]
              },
            ],
            "questionName": "Please rate how much you agree or disagree with the following statements in relation to the culture of your current museum workplace: My manager supports me",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 49,
            "labels" : ["Strongly Disagree","Somewhat Disagree","Neutral","Somewhat Agree","Strongly Agree","Prefer not to answer",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.19,  0.19,  0.29,  0.20,  0.12,  0.01]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.24,  0.22,  0.24,  0.22,  0.08,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.20,  0.19,  0.27,  0.20,  0.12,  0.01]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.16,  0.18,  0.27,  0.19,  0.20,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.13,  0.18,  0.32,  0.21,  0.15,  0.01]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.18,  0.19,  0.29,  0.22,  0.11,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.25,  0.19,  0.26,  0.21,  0.09,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.22,  0.20,  0.27,  0.19,  0.10,  0.01]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.22,  0.20,  0.29,  0.18,  0.10,  0.01]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.16,  0.15,  0.28,  0.25,  0.16,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.17,  0.13,  0.37,  0.21,  0.12,  0.01]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.07,  0.17,  0.39,  0.20,  0.18,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.15,  0.21,  0.24,  0.21,  0.18,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.16,  0.19,  0.31,  0.22,  0.11,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.13,  0.13,  0.25,  0.21,  0.29,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.11,  0.18,  0.34,  0.19,  0.18,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.08,  0.15,  0.35,  0.19,  0.23,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.25,  0.18,  0.28,  0.18,  0.11,  0.01]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.15,  0.18,  0.28,  0.24,  0.15,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.17,  0.19,  0.33,  0.20,  0.10,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.25,  0.21,  0.25,  0.20,  0.09,  0.01]
              },
            ],
            "questionName": "Please rate how much you agree or disagree with the following statements in relation to the culture of your current museum workplace: I believe performance reviews contribute to growth and/or advancement in my institution",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 50,
            "labels" : ["Strongly Disagree","Somewhat Disagree","Neutral","Somewhat Agree","Strongly Agree","Prefer not to answer",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.28,  0.32,  0.18,  0.16,  0.06,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.23,  0.28,  0.23,  0.19,  0.07,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.27,  0.32,  0.18,  0.16,  0.06,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.35,  0.30,  0.13,  0.18,  0.04,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.29,  0.34,  0.17,  0.13,  0.06,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.38,  0.37,  0.09,  0.11,  0.05,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.24,  0.31,  0.20,  0.18,  0.07,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.24,  0.33,  0.21,  0.15,  0.07,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.27,  0.33,  0.19,  0.15,  0.06,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.30,  0.28,  0.20,  0.14,  0.08,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.27,  0.38,  0.19,  0.11,  0.05,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.42,  0.29,  0.12,  0.09,  0.08,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.36,  0.29,  0.12,  0.17,  0.07,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.31,  0.32,  0.14,  0.18,  0.04,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.29,  0.38,  0.17,  0.08,  0.08,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.39,  0.28,  0.14,  0.14,  0.05,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.19,  0.44,  0.25,  0.06,  0.06,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.30,  0.36,  0.12,  0.15,  0.07,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.36,  0.28,  0.15,  0.13,  0.08,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.35,  0.31,  0.15,  0.14,  0.05,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.19,  0.34,  0.23,  0.18,  0.06,  0.00]
              },
            ],
            "questionName": "Please rate how much you agree or disagree with the following statements in relation to the culture of your current museum workplace: Diversity and difference are not celebrated in this organization",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 51,
            "labels" : ["Strongly Disagree","Somewhat Disagree","Neutral","Somewhat Agree","Strongly Agree","Prefer not to answer",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.02,  0.05,  0.11,  0.39,  0.42,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.03,  0.06,  0.11,  0.41,  0.39,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.02,  0.05,  0.12,  0.39,  0.42,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.02,  0.06,  0.10,  0.37,  0.46,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.01,  0.04,  0.12,  0.42,  0.41,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.02,  0.00,  0.11,  0.42,  0.43,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.02,  0.06,  0.11,  0.38,  0.43,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.03,  0.05,  0.11,  0.40,  0.41,  0.01]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.01,  0.05,  0.11,  0.40,  0.43,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.02,  0.05,  0.13,  0.39,  0.41,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.02,  0.03,  0.08,  0.44,  0.43,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.02,  0.03,  0.07,  0.43,  0.46,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.03,  0.06,  0.11,  0.36,  0.45,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.01,  0.04,  0.12,  0.41,  0.42,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  0.04,  0.04,  0.50,  0.42,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.02,  0.05,  0.08,  0.37,  0.48,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.02,  0.06,  0.17,  0.40,  0.33,  0.02]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.02,  0.08,  0.12,  0.38,  0.39,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.01,  0.05,  0.09,  0.38,  0.46,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.02,  0.04,  0.07,  0.36,  0.50,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.02,  0.05,  0.14,  0.42,  0.36,  0.00]
              },
            ],
            "questionName": "Please rate how much you agree or disagree with the following statements in relation to the culture of your current museum workplace: I believe that what I do here is meaningful",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 52,
            "labels" : ["Strongly Disagree","Somewhat Disagree","Neutral","Somewhat Agree","Strongly Agree","Prefer not to answer",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.22,  0.20,  0.18,  0.25,  0.15,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.19,  0.19,  0.17,  0.26,  0.20,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.22,  0.21,  0.18,  0.25,  0.15,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.26,  0.19,  0.19,  0.21,  0.15,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.28,  0.19,  0.17,  0.26,  0.11,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.21,  0.18,  0.19,  0.26,  0.14,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.19,  0.21,  0.18,  0.24,  0.18,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.20,  0.20,  0.18,  0.25,  0.17,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.22,  0.17,  0.16,  0.30,  0.15,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.28,  0.22,  0.17,  0.22,  0.11,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.28,  0.26,  0.18,  0.17,  0.12,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.33,  0.18,  0.18,  0.21,  0.10,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.29,  0.22,  0.14,  0.20,  0.15,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.19,  0.20,  0.23,  0.25,  0.13,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.17,  0.21,  0.17,  0.25,  0.21,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.31,  0.20,  0.18,  0.20,  0.11,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.29,  0.17,  0.15,  0.29,  0.10,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.22,  0.22,  0.16,  0.24,  0.17,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.29,  0.19,  0.17,  0.23,  0.12,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.22,  0.23,  0.20,  0.24,  0.10,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.17,  0.20,  0.17,  0.27,  0.19,  0.00]
              },
            ],
            "questionName": "Please rate how much you agree or disagree with the following statements in relation to the culture of your current museum workplace: The culture of my workplace negatively affects my mental and/or physical health",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 53,
            "labels" : ["Strongly Disagree","Somewhat Disagree","Neutral","Somewhat Agree","Strongly Agree","Prefer not to answer",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.23,  0.26,  0.26,  0.16,  0.09,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.14,  0.25,  0.24,  0.22,  0.14,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.22,  0.26,  0.26,  0.16,  0.09,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.32,  0.23,  0.23,  0.15,  0.08,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.27,  0.27,  0.28,  0.12,  0.06,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.23,  0.27,  0.27,  0.16,  0.06,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.20,  0.26,  0.24,  0.18,  0.11,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.18,  0.23,  0.30,  0.18,  0.11,  0.01]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.21,  0.26,  0.23,  0.19,  0.10,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.30,  0.30,  0.20,  0.12,  0.08,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.33,  0.28,  0.23,  0.08,  0.08,  0.01]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.33,  0.24,  0.26,  0.12,  0.04,  0.01]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.31,  0.24,  0.26,  0.14,  0.05,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.22,  0.31,  0.28,  0.12,  0.07,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.29,  0.25,  0.29,  0.17,  0.00,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.36,  0.23,  0.23,  0.12,  0.06,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.29,  0.25,  0.33,  0.06,  0.04,  0.02]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.26,  0.25,  0.27,  0.15,  0.07,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.26,  0.27,  0.25,  0.13,  0.08,  0.01]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.28,  0.30,  0.26,  0.13,  0.03,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.14,  0.26,  0.26,  0.20,  0.13,  0.00]
              },
            ],
            "questionName": "Please rate how much you agree or disagree with the following statements in relation to the culture of your current museum workplace: Mistakes are held against you in this organization",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 54,
            "labels" : ["Strongly Disagree","Somewhat Disagree","Neutral","Somewhat Agree","Strongly Agree","Prefer not to answer",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.07,  0.14,  0.26,  0.33,  0.20,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.09,  0.16,  0.26,  0.34,  0.15,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.07,  0.14,  0.25,  0.33,  0.20,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.08,  0.13,  0.19,  0.33,  0.28,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.05,  0.13,  0.27,  0.32,  0.23,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.07,  0.12,  0.26,  0.35,  0.19,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.08,  0.17,  0.27,  0.31,  0.17,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.09,  0.15,  0.28,  0.32,  0.16,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.06,  0.16,  0.24,  0.35,  0.19,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.04,  0.11,  0.24,  0.29,  0.32,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.06,  0.09,  0.24,  0.32,  0.29,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.04,  0.11,  0.31,  0.29,  0.25,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.06,  0.13,  0.25,  0.32,  0.24,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.08,  0.13,  0.26,  0.35,  0.18,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.08,  0.00,  0.38,  0.38,  0.17,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.05,  0.11,  0.24,  0.30,  0.31,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.04,  0.13,  0.38,  0.27,  0.19,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.09,  0.16,  0.18,  0.36,  0.20,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.07,  0.12,  0.28,  0.31,  0.22,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.07,  0.10,  0.22,  0.37,  0.25,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.08,  0.17,  0.27,  0.33,  0.15,  0.00]
              },
            ],
            "questionName": "Please rate how much you agree or disagree with the following statements in relation to the culture of your current museum workplace: I would recommend this workplace to friends and family",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 55,
            "labels" : ["Strongly Disagree","Somewhat Disagree","Neutral","Somewhat Agree","Strongly Agree","Prefer not to answer",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.14,  0.24,  0.19,  0.24,  0.19,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.10,  0.22,  0.19,  0.27,  0.22,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.14,  0.24,  0.19,  0.24,  0.19,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.20,  0.25,  0.15,  0.25,  0.15,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.15,  0.26,  0.21,  0.21,  0.17,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.17,  0.27,  0.21,  0.22,  0.13,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.11,  0.23,  0.18,  0.27,  0.20,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.11,  0.23,  0.20,  0.24,  0.21,  0.01]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.14,  0.25,  0.18,  0.26,  0.16,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.17,  0.21,  0.21,  0.22,  0.18,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.18,  0.30,  0.18,  0.16,  0.18,  0.01]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.21,  0.27,  0.23,  0.17,  0.13,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.20,  0.20,  0.19,  0.25,  0.16,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.14,  0.28,  0.17,  0.22,  0.18,  0.01]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.25,  0.29,  0.25,  0.17,  0.04,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.22,  0.25,  0.18,  0.21,  0.14,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.10,  0.25,  0.23,  0.19,  0.21,  0.02]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.18,  0.25,  0.14,  0.19,  0.24,  0.01]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.17,  0.24,  0.20,  0.23,  0.15,  0.01]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.16,  0.25,  0.16,  0.26,  0.17,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.09,  0.23,  0.20,  0.26,  0.22,  0.00]
              },
            ],
            "questionName": "Please rate how much you agree or disagree with the following statements in relation to the culture of your current museum workplace: I don’t feel that I have a voice in decision making in this organization",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 56,
            "labels" : ["Strongly Disagree","Somewhat Disagree","Neutral","Somewhat Agree","Strongly Agree","Prefer not to answer",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.20,  0.21,  0.32,  0.16,  0.08,  0.02]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.19,  0.26,  0.29,  0.17,  0.08,  0.02]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.20,  0.21,  0.33,  0.17,  0.08,  0.03]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.09,  0.15,  0.35,  0.24,  0.15,  0.02]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.21,  0.20,  0.33,  0.16,  0.06,  0.03]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.19,  0.22,  0.30,  0.20,  0.07,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.22,  0.21,  0.32,  0.15,  0.07,  0.03]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.24,  0.20,  0.30,  0.16,  0.07,  0.02]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.22,  0.23,  0.29,  0.15,  0.08,  0.03]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.20,  0.25,  0.33,  0.13,  0.06,  0.02]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.09,  0.19,  0.39,  0.20,  0.07,  0.06]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.17,  0.21,  0.32,  0.17,  0.11,  0.02]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.18,  0.18,  0.37,  0.16,  0.08,  0.02]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.13,  0.19,  0.35,  0.21,  0.11,  0.01]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.04,  0.17,  0.29,  0.38,  0.13,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.16,  0.18,  0.34,  0.19,  0.11,  0.03]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.17,  0.27,  0.44,  0.08,  0.02,  0.02]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.23,  0.25,  0.37,  0.11,  0.03,  0.02]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.20,  0.18,  0.33,  0.14,  0.12,  0.03]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.15,  0.14,  0.33,  0.22,  0.11,  0.04]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.23,  0.25,  0.30,  0.16,  0.05,  0.02]
              },
            ],
            "questionName": "Please rate how much you agree or disagree with the following statements in relation to the culture of your current museum workplace: My institution provides management and/or leadership training for all supervisors",
            "graphType": "column",
            "questionType": "radiogroup"
          },
                {
            "id": 57,
            "labels" : ["Strongly Disagree","Somewhat Disagree","Neutral","Somewhat Agree","Strongly Agree","Prefer not to answer",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.12,  0.16,  0.31,  0.24,  0.15,  0.02]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.19,  0.20,  0.30,  0.21,  0.09,  0.01]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.12,  0.16,  0.31,  0.25,  0.14,  0.02]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.07,  0.14,  0.26,  0.27,  0.26,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.08,  0.14,  0.32,  0.26,  0.18,  0.03]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.14,  0.16,  0.35,  0.21,  0.12,  0.02]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.14,  0.18,  0.31,  0.25,  0.11,  0.01]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.14,  0.17,  0.30,  0.24,  0.14,  0.02]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.15,  0.18,  0.28,  0.22,  0.15,  0.01]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.08,  0.16,  0.36,  0.20,  0.19,  0.02]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.08,  0.16,  0.30,  0.25,  0.18,  0.03]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.06,  0.13,  0.28,  0.25,  0.26,  0.02]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.07,  0.14,  0.30,  0.27,  0.20,  0.02]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.10,  0.13,  0.33,  0.29,  0.13,  0.01]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  0.13,  0.17,  0.17,  0.54,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.07,  0.11,  0.28,  0.28,  0.25,  0.01]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.04,  0.04,  0.42,  0.29,  0.17,  0.04]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.08,  0.18,  0.26,  0.34,  0.12,  0.02]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.10,  0.13,  0.36,  0.19,  0.20,  0.02]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.09,  0.13,  0.30,  0.29,  0.15,  0.03]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.16,  0.20,  0.31,  0.23,  0.09,  0.01]
              },
            ],
            "questionName": "Please rate how much you agree or disagree with the following statements in relation to the culture of your current museum workplace: People in my organization are held accountable for discrimination and harassment",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 58,
            "labels" : ["Strongly Disagree","Somewhat Disagree","Neutral","Somewhat Agree","Strongly Agree","Prefer not to answer",],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.29,  0.22,  0.19,  0.21,  0.08,  0.01]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.23,  0.22,  0.22,  0.23,  0.10,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.29,  0.22,  0.19,  0.21,  0.08,  0.01]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.35,  0.19,  0.16,  0.23,  0.08,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.33,  0.21,  0.18,  0.20,  0.07,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.29,  0.20,  0.19,  0.24,  0.08,  0.01]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.29,  0.21,  0.21,  0.20,  0.08,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.26,  0.22,  0.21,  0.20,  0.09,  0.01]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.28,  0.24,  0.20,  0.22,  0.06,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.36,  0.21,  0.18,  0.18,  0.08,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.33,  0.24,  0.13,  0.23,  0.08,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.37,  0.20,  0.18,  0.17,  0.07,  0.01]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.35,  0.18,  0.18,  0.22,  0.07,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.31,  0.20,  0.18,  0.22,  0.09,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.38,  0.21,  0.17,  0.21,  0.04,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.36,  0.20,  0.17,  0.19,  0.07,  0.01]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.40,  0.21,  0.19,  0.13,  0.08,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.26,  0.25,  0.14,  0.26,  0.09,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.40,  0.17,  0.17,  0.20,  0.05,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.28,  0.21,  0.19,  0.22,  0.09,  0.01]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.23,  0.24,  0.21,  0.21,  0.10,  0.00]
              },
            ],
            "questionName": "Please rate how much you agree or disagree with the following statements in relation to the culture of your current museum workplace: I feel like I have to hide some of who I am working in this organization",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 59,
            "labels" : ["All", "Men", "Not Men","White","Not White"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [2.71, 1.00, 0.68, 1.00, 0.99]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [1.46, 1.00, 0.60, 1.00, 1.09]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [2.63, 1.00, 0.69, 1.00, 0.95]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [3.84, 1.00, 0.91, 1.00, 0.91]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [3.56, 1.00, 0.71, 1.00, 0.98]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [3.15, 1.00, 0.76, 1.00, 1.05]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [2.04, 1.00, 0.64, 1.00, 0.95]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [1.92, 1.00, 0.53, 1.00, 1.29]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [2.31, 1.00, 0.63, 1.00, 0.82]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [3.67, 1.00, 0.97, 1.00, 0.69]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [4.47, 1.00, 0.75, 1.00, 0.89]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [4.58, 1.00, 0.72, 1.00, 0.97]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [3.50, 1.00, 0.59, 1.00, 1.14]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [3.22, 1.00, 0.78, 1.00, 0.91]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [4.83, 1.00, 0.81, 1.00, 1.40]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [4.43, 1.00, 0.86, 1.00, 1.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [3.56, 1.00, 0.54, 1.00, 0.97]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [2.38, 1.00, 0.39, 1.00, 0.80]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [3.63, 1.00, 0.67, 1.00, 1.05]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [3.58, 1.00, 0.58, 1.00, 0.80]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [1.47, 1.00, 0.67, 1.00, 0.86]
              },
            ],
            "questionName": "Mean culture score - normalized for higher scores to be better (includes the previous thirteen culture questions)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 60,
            "labels" : ["My major accomplishments have been acknowledged or recognized ","I wasn’t given appropriate resources, materials, or time to execute a job task or responsibility","I was given opportunities to do work that will likely help me advance","Someone took credit for my accomplishment","I developed positive relationships with my coworkers","Someone I work with was unfairly blamed or criticized for something","I was unfairly blamed or criticized for something","Another employee yelled, raised their voice, or spoke to me in an unprofessional manner","None of these"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.57,  0.41,  0.41,  0.19,  0.83,  0.26,  0.18,  0.24,  0.03]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.53,  0.37,  0.38,  0.22,  0.82,  0.30,  0.21,  0.28,  0.03]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.55,  0.40,  0.40,  0.19,  0.83,  0.26,  0.19,  0.24,  0.03]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.61,  0.37,  0.44,  0.16,  0.84,  0.24,  0.18,  0.20,  0.02]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.58,  0.42,  0.44,  0.17,  0.84,  0.22,  0.16,  0.20,  0.03]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.58,  0.41,  0.39,  0.22,  0.85,  0.25,  0.19,  0.24,  0.04]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.52,  0.43,  0.40,  0.17,  0.83,  0.29,  0.19,  0.27,  0.04]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.57,  0.42,  0.38,  0.21,  0.80,  0.24,  0.19,  0.26,  0.04]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.55,  0.42,  0.43,  0.20,  0.82,  0.30,  0.21,  0.27,  0.04]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.59,  0.33,  0.50,  0.17,  0.85,  0.25,  0.11,  0.19,  0.02]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.59,  0.33,  0.49,  0.15,  0.87,  0.26,  0.15,  0.19,  0.01]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.65,  0.39,  0.53,  0.14,  0.82,  0.23,  0.14,  0.18,  0.03]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.54,  0.39,  0.35,  0.16,  0.85,  0.21,  0.22,  0.19,  0.04]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.55,  0.44,  0.42,  0.16,  0.84,  0.27,  0.17,  0.24,  0.03]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.54,  0.38,  0.50,  0.21,  0.63,  0.17,  0.21,  0.33,  0.04]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.63,  0.34,  0.46,  0.14,  0.85,  0.20,  0.16,  0.18,  0.03]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.54,  0.52,  0.54,  0.21,  0.81,  0.27,  0.19,  0.25,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.50,  0.49,  0.38,  0.19,  0.89,  0.34,  0.26,  0.25,  0.02]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.58,  0.38,  0.41,  0.16,  0.84,  0.25,  0.19,  0.18,  0.03]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.57,  0.38,  0.40,  0.17,  0.81,  0.23,  0.18,  0.25,  0.05]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.54,  0.43,  0.40,  0.21,  0.82,  0.28,  0.17,  0.27,  0.03]
              },
            ],
            "questionName": "In the past 12 months, have you experienced any of the following in your museum workplace? Select all that apply.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 62,
            "labels" : ["The museum’s mission, vision, and/or values","The museum board’s priorities","Input from museum staff members","The interests of current museum visitors","The interests of the communities around the museum","None of the above/ I don't know"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.58,  0.68,  0.30,  0.24,  0.31,  0.12]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.55,  0.74,  0.22,  0.27,  0.28,  0.11]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.58,  0.69,  0.29,  0.25,  0.30,  0.12]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.72,  0.36,  0.43,  0.28,  0.41,  0.14]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.57,  0.72,  0.34,  0.21,  0.29,  0.12]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.59,  0.72,  0.32,  0.33,  0.38,  0.10]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.50,  0.76,  0.24,  0.24,  0.28,  0.13]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.54,  0.70,  0.25,  0.22,  0.23,  0.14]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.58,  0.65,  0.29,  0.22,  0.36,  0.12]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.57,  0.73,  0.34,  0.26,  0.30,  0.13]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.70,  0.68,  0.38,  0.25,  0.29,  0.13]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.59,  0.63,  0.47,  0.19,  0.39,  0.15]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.59,  0.64,  0.37,  0.31,  0.36,  0.09]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.63,  0.69,  0.32,  0.26,  0.36,  0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.79,  0.29,  0.58,  0.42,  0.67,  0.08]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.67,  0.51,  0.44,  0.23,  0.40,  0.15]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.48,  0.90,  0.25,  0.21,  0.27,  0.06]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.62,  0.65,  0.30,  0.23,  0.38,  0.10]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.55,  0.69,  0.36,  0.27,  0.33,  0.14]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.71,  0.64,  0.37,  0.26,  0.35,  0.09]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.52,  0.77,  0.19,  0.23,  0.23,  0.13]
              },
            ],
            "questionName": "What/Who do you believe has a large impact on your museum leadership’s decisions? Please select all that apply.  ",
            "graphType": "column",
            "questionType": "radiogroup"
          },
        ]
      }
    ]